import axios from 'axios';
import environment from '@/environment.js';

export default {
  entityCopyTemplate(payload) {
    return axios.post(
      `${environment.DATABOT_TEMPLATES}/api/client/entities/copy`,
      payload,
    );
  },
  listByBotId(botId) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/client/entities/list/${botId}`,
    );
  },
  deleteManyTemplateEntities(bot_id, template) {
    return axios.delete(
      `${environment.DATABOT_TEMPLATES}/api/client/entities/delete/by_template/${bot_id}?template=${template}`
      );
  },
};
