import api from '@/services/api/dt_sections';

const state = () => ({
  dtSections: [],
  selectedSection: null,
});

const getters = {};
const actions = {
  async listByTemplateName({ commit }, name) {
    let resp = await api.listByTemplateName(name);
    commit('setDtSections', resp.data);
    return resp.data;
  },
};
const mutations = {
  setDtSections(state, data) {
    state.dtSections = data;
  },
  setSelectedSection(state, data) {
    state.selectedSection = data;
  },
};

const namespaced = true;

export default { getters, actions, mutations, state, namespaced };
