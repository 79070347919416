import axios from 'axios';
import environment from '@/environment.js';

export default {
  list() {
    return axios.get(`${environment.URL_MICRO_CONVERSATIONS}/session_logs`);
  },
  update(id, payload) {
    return axios.put(
      `${environment.URL_MICRO_CONVERSATIONS}/session_logs/${id}`,
      payload,
    );
  },
  create(payload) {
    // replace from to sender
    payload.sender = payload.from;
    return axios.post(
      `${environment.URL_MICRO_CONVERSATIONS}/session_logs`,
      payload,
    );
  },
  delete(id) {
    return axios.delete(
      `${environment.URL_MICRO_CONVERSATIONS}/session_logs/${id}`,
    );
  },
  byConversationId(conversationId) {
    return axios.get(
      `${environment.URL_MICRO_CONVERSATIONS}/session_logs/by_conversation_id/${conversationId}`,
    );
  },
};
