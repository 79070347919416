// Instancia para socket rails - notificaciones en dashboard

import store from '@/store/store';
import { createConsumer } from "@rails/actioncable";
import { v4 as uuidv4 } from 'uuid';
import environment from '../../environment';

let find_http_url = /https?:\/\//;
let w_url = environment.url_env.includes(0, 'localhost') ? 'ws' : 'wss';

const filter_url = environment.url_env.replace(find_http_url, '');
const consumer = createConsumer(`${w_url}://${filter_url}/cable`);

const module = {
  namespaced: true,
  state: {
    subscription: null
  },
  actions: {
    // Activar subscripción
    initializeSocketRails({ commit, state }, { bot_id, user_id }) {
      // Validación si es que ya está subscrito se desconecte del bot anterior y conecte con el nuevo seleccionado
      if (state.subscription) {
        console.log("Ya existe una suscripción activa, desconectando...");
        state.subscription.unsubscribe(); // Cancelar la suscripción existente
        commit('setSubscription', null); // Limpiar el estado de la suscripción
      }
      const newSubscription = consumer.subscriptions.create(
        { channel: "NotificationsChannel", bot_id, user_id },
        {
          received: (data) => {
            // console.log("Received data------>", data);

            if (store.state.selected_bot.id == data.data.bot_id) {
              store.commit('setNotificationBullet', true);
              store.commit('setAddNotification', { new_notifications: data.data, event_type: data.event_type, read: data.read, user_id });
            }
          },
          connected() {
            console.log("Connected to NotificationsChannel");
          },
          disconnected() {
            console.log("Disconnected from NotificationsChannel");
          },
          userTagged(message) {
            this.perform('user_tagged', { message });
          }
        }
      );
      commit('setSubscription', newSubscription);
      return newSubscription;

    },
    newConversation({ commit, state }, new_conversation) {
      // Payload para socket - evento de nueva conversacion
      // console.log("new_conversation------->", new_conversation);
      let message = {
        type: "conversation_new",
        bot_id: new_conversation.bot_id,
        event_id: new_conversation.conversation_id, // uuidv4()
        icon: "/img/icons/notification-conversation.svg",
        link: "/databot-live-2",
        categories: [new_conversation.category]
      };
      state.subscription.perform('new_conversation', { message });
    },
    createNewTicketNotification({ commit, state }, ticket) {
      // Payload para socket - evento de nuevo ticket
      let message = {
        type: "ticket_new",
        bot_id: ticket?.bot_id, // bot a quien va
        event_id: uuidv4(),
        tracking_code: ticket?.tracking_code,
        subject: ticket?.subject,
        icon: "/img/icons/notification-ticket.svg",
        link: "/tickets",
      };
      state.subscription.perform('create_new_ticket_notification', { message });
    },
    createNewMessageTicket({ commit, state }, new_message) {
      // Payload para socket - evento de nuevo mensaje en el ticket existente
      let message = {
        type: "ticket_message",
        bot_id: new_message.ticket.bot_id,
        event_id: uuidv4(),
        tracking_code: new_message?.ticket?.tracking_code,
        icon: "/img/icons/notification-ticket-update.svg",
        link: "/tickets",
      };
      if (new_message?.ticket?.user_bot_id) message.user_id = new_message?.ticket?.user_bot_id;
      state.subscription.perform('create_new_message_ticket', { message });
    },
    transferConversation({ commit, state }, { from, to }) {
      // Payload para socket - tranferir a agente
      // console.log("from->", from);
      // console.log("to->", to);
      let message = {
        type: "agent_transfer",
        bot_id: store.state.selected_bot.id,
        event_id: uuidv4(),
        user_id: to.user_id, //usuario de destino en la transferencia
        from_agent: from.name, // agente que transfiere la conversacion
        icon: "/img/icons/notification-shunt.svg",
        link: "/databot-live-2"
      }
      state.subscription.perform('transfer_conversation', { message });
    },
  },
  mutations: {
    setSubscription(state, payload) {
      // Guarda subscription
      state.subscription = payload
    }
  },
  getters: {}
}

export default module;