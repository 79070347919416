import api from '@/services/api/dt_client_business_types.js';

const state = () => ({
  dtClientBusinessType: [],
});

const getters = {};
const actions = {
  async businessTypesListAll({ commit }) {
    let resp = (await api.businessTypesListAll()).data;
    return resp;
  },
  async businessTypeCreate({ commit }, payload) {
    let resp = (await api.businessTypeCreate(payload)).data;
    return resp;
  },
};
const mutations = {
  setAssistant(state, payload) {
    state.assistant = payload;
  },
};

const namespaced = true;

export default { getters, actions, mutations, state, namespaced };
