import Vue from "vue";
import dashboard_api from "../dashboard_api";
import { localStorageGetItem, localStorageSetItem } from "@/utils/utils";

const soundNotification = {
  install: (Vue) => {
    Vue.prototype.$playSoundNotification = (eventName, userId) =>
      new Promise((resolve, reject) => {
        let userSounds;
        let audioName;
        let audio;
        if (localStorageGetItem("userSounds")) {
          userSounds = JSON.parse(localStorageGetItem("userSounds"));
          if (userSounds.has_sound) {
            audioName = userSounds[eventName];
            if (audioName) {
              audio = new Audio(`/sounds/${audioName}`);
              audio.play();
            }
          }
        } else {
          dashboard_api.get(`/user_notification/list/${userId}`).then((response) => {
            if (response.data) {
              localStorageSetItem("userSounds", JSON.stringify(response.data));
              if (response.data.has_sound) {
                audioName = response.data[eventName];
                if (audioName) {
                  audio = new Audio(`/sounds/${audioName}`);
                  audio.play();
                }
              }
            }
          });
        }
      });
  },
};

Vue.use(soundNotification);
