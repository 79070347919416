import axios from 'axios';
import environment from '@/environment.js';

export default {
  listByTemplateName(name) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/template/sections/${name}`,
    );
  },
};
