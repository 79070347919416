import axios from 'axios';
import environment from '@/environment.js';

export default {
  getById(intent_id) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/template/intents/byid/${intent_id}`,
    );
  },
  getBySectionName(name, template) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/template/intents/bysection/${name}?template=${template}`,
    );
  },
};
