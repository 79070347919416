import numeral from 'numeral';

numeral.register('locale', 'cl', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  // abbreviations: {
  //     thousand: 'k',
  //     million: 'm',
  //     billion: 'b',
  //     trillion: 't'
  // },
  ordinal(number) {
    return number === 1 ? 'er' : 'eros';
  },
  currency: {
    symbol: '$',
  },
});

// switch between locales
numeral.locale('cl');

const clpFormat = function (value) {
  if (!value) {
    return '0';
  }

  return numeral(value).format('0,0');
};

export { clpFormat };
