import api from '@/services/api/dt_client_skills.js';

const state = () => ({
  dtClientSkills: [],
});

const getters = {};
const actions = {
  async skillCreate({ commit }, payload) {
    let resp = (await api.skillCreate(payload)).data;
    return resp;
  },
  async skillUploadLLM({ commit }, payload) {
    let resp = (await api.skillUploadLLM(payload)).data;
    return resp;
  }
};
const mutations = {
  setAssistant(state, payload) {
    state.assistant = payload;
  },
};

const namespaced = true;

export default { getters, actions, mutations, state, namespaced };
