var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-nav"},[_c('div',{staticClass:"label-box"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":"/img/brand/0-databot-negro.svg"}})]),(_vm.route.path == '/registro')?[_c('div',{staticClass:"d-flex align-item-center"},[_c('BulletSteps',{attrs:{"step":_vm.registerData.step}}),_c('span',{staticClass:"btn-select",on:{"click":_vm.dontLeaveRegister}},[_vm._v("Inicia sesión ->")])],1)]:[_c('router-link',{staticClass:"btn-select",attrs:{"to":{
          path: '/registro',
          query: {
            shop: _vm.route.query.shop,
            access_token: _vm.route.query.access_token,
            token_access: _vm.token,
            shop_domain: _vm.route.query.shop_domain || _vm.platform_name,
            code: _vm.route.query.code
          }
        }}},[_vm._v("Regístrate ->")])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }