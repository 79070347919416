import axios from 'axios';
import environment from '@/environment.js';
import dashboard_api_v2 from '@/dashboard_api_v2';

export default {
  list() {
    return dashboard_api_v2.get(`${environment.url_env}/leads`);
  },
  listOne(id) {
    return dashboard_api_v2.get(`${environment.url_env}/leads/${id}`);
  },
  update(id, payload) {
    return dashboard_api_v2.put(`${environment.url_env}/leads/${id}`, payload);
  },
  create(payload) {
    return dashboard_api_v2.post(`${environment.url_env}/leads`, payload);
  },
  delete(id) {
    return dashboard_api_v2.delete(`${environment.url_env}/leads/${id}`);
  },
  showByEmailOrPhoneOrRut(botId, { phone, email, rut }) {
    return dashboard_api_v2.post(
      `${environment.url_env}/leads/show_by_email_or_phone_or_rut`,
      {
        lead: {
          bot_id: botId,
          phone,
          email,
          rut,
        },
      },
    );
  },
};
