import axios from 'axios';
import environment from '@/environment.js';

export default {
  nodeCopy(payload) {
    return axios.post(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/copy`,
      payload,
    );
  },
  nodeComplete(payload) {
    return axios.post(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/complete`,
      payload,
    );
  },
  listByBotId(botId) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/list/${botId}`,
    );
  },
  listByIntentName(intentName, botId, template, { parent } = {}) {
    const url = `${environment.DATABOT_TEMPLATES}/api/client/nodes/answers/by_intent/${intentName}`;
    const params = { bot_id: botId, template };

    if (parent) {
        params.parent = parent;
    }

    return axios.get(url, { params });
  },
  // for main menu
  updateWelcomeTexts(answers = [], buttons = [], botId, template) {
    return axios.put(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/welcome/update`,
      {
        bot_id: botId,
        buttons,
        answers,
        template,
      },
    );
  },
  /**
   *
   * @param {*} buttons Array of buttons with structure {text, action,jump_to}
   * @param {*} botId
   */
  updateWelcomeButtons(buttons = [], botId, template) {
    return axios.post(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/welcome/buttons`,
      {
        bot_id: botId,
        buttons,
        template,
      },
    );
  },
  deleteCustomNode(botId, node) {
    console.log("AQUÍ -------------> BOTID, NODE: ", botId, node);
    return axios.delete(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/custom/node/${node}?bot_id=${botId}`
    );
  },
  deleteButtonOption(botId, nodeName) {
    return axios.delete(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/welcome/buttons/${nodeName}?bot_id=${botId}`
    );
  },
  deleteSubOption(payload) {
    return axios.put(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/welcome/buttons/suboption`,
      payload,
    );
  },
  resetWelcomeButton(payload) {
    return axios.put(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/welcome/buttons/reset`,
      payload,
    );
  },
  getIntentAssociated(nodeId, botId) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/intent_associated/${nodeId}?bot_id=${botId}`,
    );
  },
  addCustomNode(payload) {
    return axios.post(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/custom/node`,
      payload,
    );
  },
  deactivateNode(payload) {
    return axios.put(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/deactivate`,
      payload,
    );
  },
  updateCustomInteraction(payload) {
    return axios.put(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/custom/update`,
      payload,
    );
  },
  getAnswersCustom(botId) {
    console.log('🚀 Aqui *** -> botId SERVICE:', botId);
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/answers/custom/${botId}`,
    );
  },
  deleteManyTemplateNodes(bot_id, template) {
    return axios.delete(
      `${environment.DATABOT_TEMPLATES}/api/client/nodes/delete/by_template/${bot_id}?template=${template}`
      );
  }
};
