<template>
  <section class="jumpseller-layout my-4 py-4">
    <div class="container pb-md-5">
      <SlideYUpTransition
        mode="out-in"
        origin="center top">
        <div class="container-fluid">
          <div class="col-lg-12">
            <router-view></router-view>
          </div>
        </div>
      </SlideYUpTransition>
    </div>
    <br />
    <br />
    <img src="/img/icons/4-registro-elemento-3.svg" alt="flotante" class="flotante">

  </section>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';
import { mapState } from 'vuex';

export default {
  name: 'jumpseller-layout',
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  // beforeCreate() {
  //   window.onload = function () {
  //     const iframes = document.querySelectorAll('iframe');
  //     for (let i = 0; i < iframes.length; i++) {
  //       iframes[i].parentNode.removeChild(iframes[i]);
  //     }

  //     const webPusher = document.querySelector('#webpushr-prompt-wrapper');
  //     webPusher.parentNode.removeChild(webPusher);
  //   };
  // },
  computed: {
    ...mapState(['logged']),
  },
};
</script>

<style lang="scss" scoped>
.flotante {
  position: fixed;
  top: 70%;
  width: 15%;
  z-index: 2;

  @media (max-width: 1080px) {
    display: none;
  }
}
</style>
