import Vue from 'vue';
import showdown from 'showdown';

const converter = new showdown.Converter({
  openLinksInNewWindow: true,
  simplifiedAutoLink: true,
  excludeTrailingPunctuationFromURLs: true,
});

const uuid = {
  install: Vue => {
    Vue.prototype.$parseMarkdown = text => converter.makeHtml(text);
  },
};

Vue.use(uuid);
