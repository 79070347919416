import axios from 'axios';
import environment from '@/environment.js';

export default {
  list(assistant_id) {
    return axios.get(
      `${environment.RASA_URL}/api/assistants/${assistant_id}/dialog_nodes`,
    );
  },
  update(assistant_id, id, payload) {
    return axios.put(
      `${environment.RASA_URL}/api/assistants/${assistant_id}/dialog_nodes/${id}`,
      payload,
    );
  },
  create(assistant_id, payload) {
    return axios.post(
      `${environment.RASA_URL}/api/assistants/${assistant_id}/dialog_nodes`,
      payload,
    );
  },
  delete(assistant_id, id) {
    return axios.delete(
      `${environment.RASA_URL}/api/assistants/${assistant_id}/dialog_nodes/${id}`,
    );
  },
};