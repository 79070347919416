<template>
    <component class="dropdown"
               :is="tag"
               :class="[{show: isOpen}, {'dropdown': direction === 'down'}, {'dropup': direction ==='up'}]"
               aria-haspopup="true"
               :aria-expanded="isOpen"
               @click="toggleDropDown"
               v-click-outside="closeDropDown">

        <slot name="title">
            <a class="dropdown-toggle arrow-drop"
               :class="{'no-caret': hideArrow}"
               data-toggle="dropdown">
                <i :class="icon" />
                <span class="no-icon">{{title}}</span>
            </a>
        </slot>
        <ul class="dropdown-menu"
            ref="menu"
            :class="[{'dropdown-menu-right': position === 'right'}, {show: isOpen}, menuClasses, close_menu]">
            <slot></slot>
        </ul>
    </component>
</template>
<script>
export default {
  name: 'base-dropdown',
  props: {
    close_menu: {
      type: Object,
    },
    direction: {
      type: String,
      default: 'down',
    },
    title: {
      type: String,
      description: 'Dropdown title',
    },
    icon: {
      type: String,
      description: 'Icon for dropdown title',
    },
    position: {
      type: String,
      description: 'Position of dropdown menu (e.g right|left)',
    },
    menuClasses: {
      type: [String, Object],
      description: 'Dropdown menu classes',
    },
    hideArrow: {
      type: Boolean,
      description: 'Whether dropdown arrow should be hidden',
    },
    appendToBody: {
      type: Boolean,
      default: true,
      description: 'Whether dropdown should be appended to document body',
    },
    tag: {
      type: String,
      default: 'li',
      description: 'Dropdown html tag (e.g div, li etc)',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
      this.$emit('change', this.isOpen);
    },
    closeDropDown() {
      this.isOpen = false;
      this.$emit('change', this.isOpen);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  list-style-type: none;
  z-index: 20;

  .dropdown-toggle {
    cursor: pointer;
  }
  .dropdown-item {
    &:hover {
      color: #333 !important;
      transition: 0.5s;
      background-color: #fafafa;
    }
  }
  .arrow-drop {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
  }
}
</style>
