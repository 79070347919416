import api from '@/services/api/dialogNodes';

const namespaced = true;
const state = {
  dialogNodes: [],
  total: 0,
  totalPages: 0,
};
const actions = {
  list({ commit }, query) {
    return new Promise((resolve, reject) => {
      api
        .list(query)
        .then(response => {
          commit('list', response.data.payload);
          commit('totalItems', response.data.totalDocs);
          commit('totalPages', response.data.totalPages);
          resolve(response.data.payload);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  create({ commit }, { assistant_id, data }) {
    return new Promise((resolve, reject) => {
      api
        .create(assistant_id, data)
        .then(res => {
          commit('create', res.data.payload);
          resolve(res.data.payload);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  update({ commit }, { assistant_id, id, data }) {
    return new Promise((resolve, reject) => {
      api
        .update(assistant_id, id, data)
        .then(res => {
          commit('update', {
            assistant_id,
            id,
            data: res.data.payload,
          });
          resolve(res.data.payload);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  delete({ commit }, { assistant_id, id }) {
    return new Promise((resolve, reject) => {
      api
        .delete(assistant_id, id)
        .then(() => {
          commit('delete', id);
          resolve();
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};
const mutations = {
  list(state, data) {
    state.dialogNodes = data;
  },
  totalItems(state, data) {
    state.total = data;
  },
  totalPages(state, data) {
    state.totalPages = data;
  },
  create(state, data) {
    state.dialogNodes.push(data);
  },
  update(state, { id, data }) {
    let indexToUpdate = state.dialogNodes.findIndex(el => el._id == id);
    state.dialogNodes.splice(indexToUpdate, 1, {
      ...data,
    });
  },
  delete(state, id) {
    let indexToDelete = state.dialogNodes.findIndex(el => el._id == id);
    state.dialogNodes.splice(indexToDelete, 1);
    state.total -= 1;
  },
};
const getters = {};

export default { getters, actions, mutations, state, namespaced };