import axios from 'axios';
import environment from '@/environment.js';

export default {
  businessTypesListAll() {
    return axios.get(
      `${environment.url_env}/super_admin/business_type_name`
    );
  },
  businessTypeCreate(payload) {
    return axios.post(
      `${environment.url_env}/super_admin/newbusiness_type`,
      payload,
    );
  },
  updateBusinessType(botId, payload) {
    return axios.put(
      `${environment.url_env}/bot/${botId}`,
      payload,
    );
  }
};
