import api from '@/services/api/dt_client_nodes.js';
import { template } from 'lodash';

const state = () => ({
  dtClientNodes: [],
});

const getters = {};
const actions = {
  async nodeCopy({ commit }, payload) {
    let resp = (await api.nodeCopy(payload)).data;
    return resp;
  },
  async nodeComplete({ commit }, payload) {
    let resp = (await api.nodeComplete(payload)).data;
    return resp;
  },
  async listByBotId({ commit }, botId) {
    let resp = (await api.listByBotId(botId)).data;
    return resp;
  },
  async listByIntentName({ commit }, { intentName, botId, template, parent } = {}) {
    console.log(
      '🚀 Aqui *** -> intentName, botId, template:',
      intentName,
      botId,
      template,
      parent
    );
    let resp = (await api.listByIntentName(intentName, botId, template, { parent })).data;
    return resp;
  },
  async updateWelcomeTexts(
    { commit },
    { answers, buttons, bot_id, template } = {},
  ) {
    console.log(
      '🚀 Aqui *** -> answers, buttons, bot_id:',
      answers,
      buttons,
      bot_id,
      template,
    );
    let resp = (
      await api.updateWelcomeTexts(answers, buttons, bot_id, template)
    ).data;
    return resp;
  },
  async updateWelcomeButtons({ commit }, { buttons, bot_id, template } = {}) {
    let resp = (await api.updateWelcomeButtons(buttons, bot_id, template)).data;
    return resp;
  },
  async deleteCustomNode({ commit }, { bot_id, node } = {}) {
    let resp = (await api.deleteCustomNode(bot_id, node)).data;
    return resp;
  },
  async deleteButtonOption({ commit }, { bot_id, node_name } = {}) {
    let resp = (await api.deleteButtonOption(bot_id, node_name)).data;
    return resp;
  },
  async deleteSubOption({ commit }, payload = {}) {
    console.log('🚀 Aqui *** -> payload:', payload);
    let resp = (await api.deleteSubOption(payload)).data;
    return resp;
  },
  async resetWelcomeButton({ commit }, payload = {}) {
    let resp = (await api.resetWelcomeButton(payload)).data;
    return resp;
  },
  async getIntentAssociated({ commit }, { nodeId, bot_id } = {}) {
    let resp = (await api.getIntentAssociated(nodeId, bot_id)).data;
    return resp;
  },
  async addCustomNode({ commit }, payload = {}) {
    let resp = (await api.addCustomNode(payload)).data;
    return resp;
  },
  async deactivateNode({ commit }, payload = {}) {
    let resp = (await api.deactivateNode(payload)).data;
    return resp;
  },
  async updateCustomInteraction({ commit }, payload = {}) {
    let resp = (await api.updateCustomInteraction(payload)).data;
    return resp;
  },
  async getAnswersCustom({ commit }, botId) {
    let resp = (await api.getAnswersCustom(botId)).data;
    return resp;
  },
  async deleteManyTemplateNodes({ commit }, { bot_id, template } = {}) {
    let resp = (await api.deleteManyTemplateNodes(bot_id, template)).data;
    return resp;
  }
};
const mutations = {
  setAssistant(state, payload) {
    state.assistant = payload;
  },
};

const namespaced = true;

export default { getters, actions, mutations, state, namespaced };
