<template>
  <section class="auth-layout">
    <div class="container">
      <div class="auth-nav">
        <div class="label-box">
          <router-link to="/">
            <img class="logo" src="img/brand/0-databot-negro.svg" />
          </router-link>
          <template v-if="$route.path == '/registro'">
            <router-link
              class="btn btn-base sm"
              :to="{
                path: '/login',
                query: {
                  shop: $route.query.shop,
                  access_token: $route.query.access_token,
                  token_access: token,
                  shop_domain: $route.query.shop_domain || platform_name,
                  code: $route.query.code
                }
              }"
              >Inicia sesión
            </router-link>
          </template>
          <template v-else>
            <router-link
              class="btn btn-base sm"
              :to="{
                path: '/registro',
                query: {
                  shop: $route.query.shop,
                  access_token: $route.query.access_token,
                  token_access: token,
                  shop_domain: $route.query.shop_domain || platform_name,
                  code: $route.query.code
                }
              }"
              >Regístrate</router-link
            >
          </template>
        </div>
      </div>
      <SlideYUpTransition mode="out-in" origin="center top">
        <div class="col-lg-12">
          <router-view />
        </div>
      </SlideYUpTransition>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import { SlideYUpTransition } from "vue2-transitions";
import { getCurrentEcommercePlatform } from "@/utils/utils";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition
  },
  data() {
    return {
      token: "",
      shop_domain: ""
    };
  },
  // beforeCreate() {
  //   window.onload = () => {
  //     const iframes = document.querySelectorAll("iframe");
  //     for (let i = 0; i < iframes.length; i++) {
  //       iframes[i].parentNode.removeChild(iframes[i]);
  //     }

  //     const webPusher = document.querySelector("#webpushr-prompt-wrapper");
  //     webPusher.parentNode.removeChild(webPusher);
  //   };
  // },
  created() {
    console.log("this.$route.query->", this.$route.query);
    let platform = getCurrentEcommercePlatform();
    this.getPlatformName(platform);
    console.log("platform->", platform);

    if (platform === "jumpseller") {
      this.getPlatformCode(this.$route.query.code);
    }
  },
  mounted() {
    this.getIntegrationQuery();
  },
  computed: {
    ...mapState(["logged", "platform_name", "platform_code"])
  },
  methods: {
    ...mapMutations(["getPlatformName", "getPlatformCode", "getPlatformShop", "getPlatformAccess"]),

    async getIntegrationQuery() {
      this.token = this.$route.query.access_token;
      this.shop_domain = this.$route.query.shop_domain;

      if (this.platform_name === "shopify") {
        this.getPlatformShop(this.$route.query.shop);
        this.getPlatformAccess(this.$route.query.access_token);
        let code = this.$route.query.code;
        this.shop_domain = this.$route.query.shop;

        // se verifica si el token access ya estaba presente
        if (this.$route.query.access_token) {
          // console.log("🚀 Aqui *** -> this.$route.query.access_token", this.$route.query.access_token);
          this.token = this.$route.query.access_token;
        } else {
          let response = await axios.post(`${environment.socket_url}/shopify/access_token`, {
            shop: this.shop_domain,
            code
          });

          this.token = response.data.payload.access_token;
          this.getPlatformAccess(this.token);
        }
      }

      // if (this.$route.query.token_access) {
      //   this.token = this.$route.query.token_access;
      // } else {
      else if (this.platform_name === "jumpseller") {
        axios
          .post(
            `https://accounts.jumpseller.com/oauth/token?grant_type=authorization_code&client_id=c7176f690639bb06f46480e2cb9e3ca4986c79df1bdb996ac41f9b758e62066b&client_secret=0f0e494b74843a0cbe135d387bbc350beaf7961d9053da84a76528716bc5dde7&code=${this.platform_code}&redirect_uri=https://ia.databot.cl/registro?shop=jumpseller`
          )
          .then(response => {
            this.token = response.data.access_token;
          });
      }
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
.auth-layout {
  width: 100%;
  .auth-nav {
    padding: 1rem 2rem;
    
    .logo {
      width: 100%;
      
      @media (max-width: 480px) {
        width: 120px;
      }
    }
  }
  .view {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>