import { io } from 'socket.io-client';
import environment from '../environment';
import store from '@/store/store.js';



// const socket = io("https://automations-prod.azurewebsites.net");

const socket = io(environment.url_automations);

socket.on('CONNECTED', async (data) => {
    console.log('CONECTADO LOCAL 5500');
});

socket.on('LOAD_PRODUCTS', async (data) => {
    console.log("LOAD_PRODUCTS->", data.progress.toFixed(0));
    // STORE
    let upload = store.state.ecommerceAccount;
    upload.upload_progress = data.progress.toFixed(0);

    store.commit('setEcommerceAccount', upload);
});

export default socket;