import axios from 'axios';
import environment from '@/environment.js';

export default {
  intentCopy(payload) {
    return axios.post(
      `${environment.DATABOT_TEMPLATES}/api/client/intents/copy`,
      payload,
    );
  },
  intentComplete(payload) {
    return axios.post(
      `${environment.DATABOT_TEMPLATES}/api/client/intents/complete`,
      payload,
    );
  },
  listByBotId(botId) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/client/intents/list/${botId}`,
    );
  },
  addCustomIntent(payload) {
    return axios.post(
      `${environment.DATABOT_TEMPLATES}/api/client/intents/create/custom`,
      payload,
    );
  },
  deleteCustomIntent(bot_id, intent) {
    console.log("AQUÍ -------------> BOTID, INTENT: ", bot_id, intent);
    return axios.delete(
      `${environment.DATABOT_TEMPLATES}/api/client/intents/delete/custom/${intent}?bot_id=${bot_id}`
    );
  },
  deleteManyTemplateIntents(bot_id, template) {
    return axios.delete(
      `${environment.DATABOT_TEMPLATES}/api/client/intents/delete/by_template/${bot_id}?template=${template}`
      );
  }
};
