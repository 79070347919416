/**
 * Este Plugin sirve para corregir errores con localstorage luego de que fue puesto en produccion
 * y se hizo algún cambio en el tipo de dato posteriormente
 */

// actualizando tipo dato de seleccion de industria SICC
import { localStorageGetItem } from "@/utils/utils";

const rawObject = localStorageGetItem("sicc");
if (rawObject) {
  const siccObject = JSON.parse(rawObject);
  if (siccObject.hasOwnProperty("company")) {
    delete siccObject.company;
    siccObject.companies = [];
    localStorage.setItem("sicc", JSON.stringify(siccObject));
  }
}
