import store from "@/store/store";

async function init(host, shop, iframe) {
  try {
    var AppBridge = window["app-bridge"];
    var AppBridgeUtils = window["app-bridge-utils"];
    var createApp = AppBridge.createApp;
    var actions = AppBridge.actions;
    var TitleBar = actions.TitleBar;

    // var Redirect = actions.Redirect;
    var apiKey = "fd2218c064466c9352de1e12cc760462";
    var redirectUri = `https://app-databot.herokuapp.com/api/auth?shop=${shop}`;

    // If your app is embedded inside Shopify, use App Bridge to redirect
    if (AppBridgeUtils.isShopifyEmbedded() || iframe) {
      let app = createApp({
        apiKey,
        host,
        forceRedirect: true,
      });
      store.commit("ecommercePlatformsModule/setShopifyAppInstance", app);
      var titleBarOptions = {
        title: "Mi primer bot con DATABOT 🤖",
      };
      var myTitleBar = TitleBar.create(app, titleBarOptions);
      // getting session
      AppBridgeUtils.getSessionToken(app).then((session) => {
        store.commit("ecommercePlatformsModule/setShopifySession", session);
      });
    } else {
      // Otherwise, redirect using the `window` object
      let app = createApp({
        apiKey,
        host,
        forceRedirect: false,
      });
      window.location.assign(redirectUri);
    }
  } catch (error) {
    console.log(error);
  }
}

export default init;
