import api from '@/services/api/dt_client_intents.js';

const state = () => ({
  dtClientIntents: [],
});

const getters = {};
const actions = {
  async intentCopy({ commit }, payload) {
    let resp = (await api.intentCopy(payload)).data;
    return resp;
  },
  async intentComplete({ commit }, payload) {
    let resp = (await api.intentComplete(payload)).data;
    return resp;
  },
  async listByBotId({ commit }, botId) {
    let resp = (await api.listByBotId(botId)).data;
    return resp;
  },
  async addCustomIntent({ commit }, payload) {
    let resp = (await api.addCustomIntent(payload)).data;
    return resp;
  },
  async deleteCustomIntent({ commit }, { bot_id, intent } = {}) {
    let resp = (await api.deleteCustomIntent(bot_id, intent)).data;
    return resp;
  },
  async deleteManyTemplateIntents({ commit }, { bot_id, template } = {}) {
    let resp = (await api.deleteManyTemplateIntents(bot_id, template)).data;
    return resp;
  }
};
const mutations = {
  setAssistant(state, payload) {
    state.assistant = payload;
  },
};

const namespaced = true;

export default { getters, actions, mutations, state, namespaced };
