<template>
  <Modal :show="show" alto="500" ancho="600" @close="$emit('close')">
    <div v-if="show" class="implementacion" slot="content">
      <tabs class="flex-column flex-md-row">
        <tab-pane>
          <span slot="title">Script</span>
          <p>{{ implementMessage }}</p>
          <br />
          <div>
            <textarea
              id="scriptBot"
              rows="4"
              v-model="implementation_script"
              @click="copyTestingCode('scriptBot')"
            />
          </div>
          <br />
          <div class="col-lg-6 justify-content-center d-flex m-auto">
            <button class="btn btn-base lg" @click="copyTestingCode('scriptBot')">
              Copiar código
            </button>
          </div>
        </tab-pane>
        <tab-pane title="Shopify">
          <span slot="title">Shopify</span>
          <p>Ingrese estos valores en la app de Databot.</p>
          <div class="info_box">
            <p>
              ID:
              <span class="destacado">
                {{ bot_id }}
              </span>
            </p>
            <p>
              Token:
              <span class="destacado">
                {{ bot_token }}
              </span>
            </p>
          </div>
          <br />
        </tab-pane>
        <tab-pane title="Mail">
          <span slot="title">Correo</span>
          <p>
            ¿Necesitas ayuda? Enviar el código de implementación vía correo.
          </p>
          <br />
          <div>
            <input placeholder="Ingresa un correo" v-model="instemail" />
            <i class="far fa-envelope" />
          </div>
          <br />
          <div class="col-lg-6 justify-content-center d-flex m-auto">
            <button class="btn btn-base lg" @click="sendmail">
              Enviar código
            </button>
          </div>
        </tab-pane>
        <tab-pane title="Wordpress">
          <span slot="title">Wordpress</span>
          <p>¡Descarga el plugin para la implementación en wordpress!</p>
          <div class="info_box">
            <p>
              Tu id:
              <span class="destacado">
                {{ bot_id }}
              </span>
            </p>
            <p>
              Tu token:
              <span class="destacado">
                {{ bot_token }}
              </span>
            </p>
          </div>
          <br />
          <div class="col-lg-6 justify-content-center d-flex m-auto">
            <a
              class="btn btn-base lg"
              type="download"
              href="/img/archivos/databot_wordpress.zip"
              >Descargar plugin
            </a>
          </div>
        </tab-pane>
        <tab-pane title="Vtex">
          <span slot="title">Vtex</span>
          <p>Ingrese estos valores en la app de Databot dentro de Vtex.</p>
          <div class="info_box">
            <p>
              ID:
              <span class="destacado">
                {{ bot_id }}
              </span>
            </p>
            <p>
              Token:
              <span class="destacado">
                {{ bot_token }}
              </span>
            </p>
          </div>
          <br />
        </tab-pane>
        <tab-pane title="GTM">
          <span slot="title">Google Tag Manager</span>
          <p>Agregue el siguiente código en una etiqueta de tipo "HTML personalizado"</p>
          <br />
          <div>
            <textarea
              id="scriptBot_gtm"
              rows="4"
              v-model="google_tag_manager_script"
              @click="copyTestingCode('scriptBot_gtm')"
            />
          </div>
          <br />
          <div class="col-lg-6 justify-content-center d-flex m-auto">
            <button class="btn btn-base lg" @click="copyTestingCode('scriptBot_gtm')">
              Copiar código
            </button>
          </div>
        </tab-pane>
      </tabs>
    </div>
  </Modal>
</template>

<script>
import dashboard_api from '../dashboard_api.js';
import environment from '@/environment.js';

export default {
  name: 'Implementacion',
  props: ['bot_id', 'bot_token', 'show'],
  data() {
    return {
      instemail: '',
      implementMessage:
        'Agrega el siguiente código en el archivo html, antes de la línea </body>.',
    };
  },
  computed: {
    implementation_script() {
      return this.bot_id == '1324'
        ? `<script defer type='text/javascript' src='https://databot-chatbot-dt.herokuapp.com/dist_files/databot.js/dist_files/databot.js' id='1324' bot='UVRQGMSPSSEGJIUT'></sc` +
            `ript><link rel='stylesheet' href='https://databot-chatbot-dt.herokuapp.com/dist_files/databot.css'>`
        : `<script defer type='text/javascript' src='${environment.url_env_bot_front}/dist_files/databot.js' id='${this.bot_id}' bot='${this.bot_token}'></sc` +
            `ript><link rel='stylesheet' href='${environment.url_env_bot_front}/dist_files/databot.css'>`;
    },
    google_tag_manager_script () {
      const scriptSrc = this.bot_id === '1324'
      ? 'https://databot-chatbot-dt.herokuapp.com/dist_files/databot.js/dist_files/databot.js'
      : `${environment.url_env_bot_front}/dist_files/databot.js`;

      const scriptId = this.bot_id === '1324' ? '1324' : this.bot_id;
      const scriptBotAttribute = this.bot_id === '1324' ? 'UVRQGMSPSSEGJIUT' : this.bot_token;

      return `<script defer type='text/javascript'>
        var script = document.createElement('script');
        script.defer = true;
        script.type = 'text/javascript';
        script.src = '${scriptSrc}';
        script.id = '${scriptId}';
        script.setAttribute('bot', '${scriptBotAttribute}');
        document.head.appendChild(script);
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '${environment.url_env_bot_front}/dist_files/databot.css';
        document.head.appendChild(link);
      <\/script>`;
    }
  },
  methods: {
    copyTestingCode(elementId) {
      const self = this;
      const testingCodeToCopy = document.getElementById(elementId);
      testingCodeToCopy.select();
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        self.$swal({
          icon: 'success',
          title: 'Se ha copiado correctamente!',
          timer: 1000,
          timerProgressBar: false,
          showConfirmButton: false,
        });
      } catch (err) {
        self.$swal({
          icon: 'error',
          title: 'Ha habido un problema',
        });
      }
      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
    sendmail() {      
      const self = this;
      self.emailloading = true;
      dashboard_api
        .post('/mails/implement', {
          email: self.instemail,
          bot_id: self.bot_id,
        })
        .then((response) => {
          self.$swal({
            icon: 'success',
            title: 'Se ha enviado el correo correctamente!',
          });
          self.show = false;
          self.emailloading = false;
        });
    },   
  }
};
</script>
<style lang="scss">
.implementacion {
  .nav-pills .nav-link {
    box-shadow: none;
    border-radius: 0.5rem;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    padding: 0.5rem 2rem;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
    margin: auto 0;
    transition: 0.5s;

    &:hover {
      background-color: #fafafa;
    }
    &.active {
      color: #181818;
      border: 1px solid #2981ef;
      background-color: #fafafa;
    }
    @media (max-width: 1024px) {
      margin: 0.5rem 0 !important;
      width: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
.implementacion {
  padding: 1rem 2rem 2rem;

  .tab-pane {
    display: flex;
    flex-direction: column;

    p {
      color: #767676;
      margin: 1rem auto;
    }
    @media (max-width: 800px) {
      grid-template-columns: 100%;
      row-gap: 10px;
    }
    .text-box {
      justify-content: space-between;
      display: flex;
      padding: 2rem;

      @media (max-width: 480px) {
        display: inline-block;
      }
      .destacado {
        font-weight: 700;
        color: #181818;
        background-color: #f2f2f2;
        padding: 0.5rem 1rem;
        border-radius: 5px;
      }
    }
  }
  textarea {
    padding: 1rem 1.5rem;
    text-align: left;
    background-color: #fafafa;
    line-height: 160%;
    cursor: pointer;
    margin: 0;
  }
  // .input_box {
  //   position: relative;

  //   input {
  //     width: 100%;

  //     &:hover ~ .fa-envelope {
  //       transition: 0.5s;
  //       opacity: 0.5;
  //     }
  //     &:focus ~ .fa-envelope {
  //       transition: 0.5s;
  //       opacity: 0;
  //     }
  //   }
  //   .fa-envelope {
  //     position: absolute;
  //     color: #b2b2b2;
  //     top: 15px;
  //     right: 15px;
  //     transition: 0.5s;
  //     opacity: 1;
  //   }
  // }
  .info_box {
    border-radius: 0.5rem;
    margin: auto 0;
  }
}
</style>
