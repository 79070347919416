import axios from 'axios';
import environment from '@/environment.js';

export default {
  list() {
    return axios.get(`${environment.URL_MICRO_CONVERSATIONS}/sessions`);
  },
  update(id, payload) {
    return axios.put(
      `${environment.URL_MICRO_CONVERSATIONS}/sessions/${id}`,
      payload,
    );
  },
  create(payload) {
    return axios.post(
      `${environment.URL_MICRO_CONVERSATIONS}/sessions`,
      payload,
    );
  },
  delete(id) {
    return axios.delete(
      `${environment.URL_MICRO_CONVERSATIONS}/sessions/${id}`,
    );
  },
  byConversationId(conversationId) {
    const query = {
      params: {
        order: 'DESC',
        // page: 1
      },
    };
    return axios.get(
      `${environment.URL_MICRO_CONVERSATIONS}/sessions/by_conversation_id/${conversationId}`, query
    );
  },
};
