<template>
  <section class="sub_menu-lvl3">
    <div class="d-flex pl-3" v-if="info.name == 'Plantillas'">
      <span
        class="d-flex align-items-center mb-2"
        v-for="(item, index) in filterItems()"
        :key="index"
      >
        <span
          @click="changeTemplate(item)"
          class="i-base i-btn i-btn-sm i-btn-lg my-2"
          :class="{
            'i-cart': item.name == 'ecommerce',
            'i-idea': item.name == 'generica',
            'btn-select-active': skill_template?.name == item.name,
          }"
          :title="item.label"
        />
        <span class="tooltip_text-sm ml-5">{{ item.label }}</span>
      </span>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import dashboard_api from '@/dashboard_api.js';

export default {
  props: ['info'],

  data() {
    return {
      selected_template: this.skill_template,
    };
  },
  computed: {
    ...mapState(['selected_bot', "skill_template", "templates"]),
  },
  methods: {
    ...mapMutations(['setTemplateValue', 'setRouterSkillBase', 'setRouterSkillBase', 'setGlobalLoading']),

    formDataBody() {
      const data = new FormData();
      const { skill_base_template } = this.selected_bot;
      // modificando en bd parametro skill_base_template
      data.append("chatbot[skill_base_template]", skill_base_template);

      return data;
    },
    save_bot() {
      const formData = this.formDataBody();
      this.$swal.showLoading();

      dashboard_api
        .put(`/bot/${this.selected_bot.id}`, formData)
        .then((resp) => {
          console.log("resp->", resp);
        })
        .catch(error => console.log("put =>", error));
    },
    changeTemplate(template) {
      this.$swal({
        title: '¿Estás seguro que deseas cambiar plantilla?',
        text: 'Se borrarán todos tus cambios y comenzarás desde el inicio',
        icon: 'warning',
        showCancelButton: true,
        buttons: {
          cancel: {
            text: 'Cancelar',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Confirmar',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(async result => {
        if (result.isConfirmed) {
          this.selected_template = template;
          this.selected_bot.skill_base_template = template.name;
          this.setGlobalLoading(true);

          if (this.selected_template.name !== this.skill_template.value) {
            // borrar todo lo copiado desde la plantilla de origen
            await Promise.all([
              await this.$store.dispatch(
                'dtClientIntentsModule/deleteManyTemplateIntents',
                {
                  bot_id: this.selected_bot.id,
                  template: this.skill_template.value
                }
              ),
              await this.$store.dispatch(
                'dtClientEntitiesModule/deleteManyTemplateEntities',
                {
                  bot_id: this.selected_bot.id,
                  template: this.skill_template.value
                }
              ),
              await this.$store.dispatch(
                'dtClientNodesModule/deleteManyTemplateNodes',
                {
                  bot_id: this.selected_bot.id,
                  template: this.skill_template.value
                }
              ),
            ]);
          };
          this.setTemplateValue(this.selected_template);

          await Promise.all([
            this.$store.dispatch('dtClientIntentsModule/intentComplete', {
              template: this.selected_template.value,
              bot_id: this.selected_bot.id,
            }),
            this.$store.dispatch('dtClientNodesModule/nodeComplete', {
              template: this.selected_template.value,
              bot_id: this.selected_bot.id,
            }),
            this.$store.dispatch('dtClientEntitiesModule/entityCopyTemplate', {
              template: this.selected_template.value,
              bot_id: this.selected_bot.id,
            }),
          ]);
          this.getSkillTemplate();
          this.save_bot();
          this.setGlobalLoading(false);

          return this.$swal({
            icon: 'success',
            title: `Plantilla cambiada a ${this.selected_template.label}`,
            showConfirmButton: true,
          });
        };
      });
    },
    async getSkillTemplate(bot) {
      if (this.selected_bot.has_skill_base) {
        let data = (
          await this.$store.dispatch(
            'dtSectionsModule/listByTemplateName',
            this.skill_template.value,
          )
        ).sectionsFront;
        if (data) {
          let skill_base = data.map(el => ({
            name: el.section_ui_name,
            path: `/chatbot-center/skill-base/${el.section_ui_name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .split(' ')
              .join('-')}`,
            original_name: el.section_name,
            bot_id: this.selected_bot.id,
          }));
          this.setRouterSkillBase(skill_base);
        };
      };
    },
    filterItems() {
      if (this.info) {
        switch (this.info.name) {
          case 'Plantillas':
            return this.templates;
            break;
          // case 'Precargadas':
          //   return this.skill_base;
          //   break;
          default:
            return [];
            break;
        };
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sub_menu-lvl3 {
  width: 100%;
  background-color: #f8fbff;

  .item-link-sm {
    justify-content: flex-start;

    &:hover {
      color: #2981ef;
      background-color: #f8fbff;
    }
  }
  .i-base:hover {
    + .tooltip_text-sm {
      visibility: visible;
      transition: 0.25s;
      opacity: 1;
      z-index: 150 !important;
    }
  }
}
</style>
