import api from '@/services/api/dt_intents.js';

const state = () => ({
  dtIntents: [],
});

const getters = {};
const actions = {
  async getById({ commit }, id) {
    let resp = (await api.getById(id)).data;
    return resp;
  },
  async getBySectionName({ commit }, { name, template } = {}) {
    console.log(
      '🚀 Aqui *** -> name, template:',
      name,
      template,
    );
    let resp = (await api.getBySectionName(name, template)).data;
    return resp;
  },
};
const mutations = {
  setAssistant(state, payload) {
    state.assistant = payload;
  },
};

const namespaced = true;

export default { getters, actions, mutations, state, namespaced };
