<template>
  <SlideXLeftTransition :duration="200">
    <section class="sub-menu change-mobile">
      <span @click="$emit('show')" class="btn-dash-sm desktop"><i class="fas fa-times" /></span>
      <span
        class="i-btn i-btn-sm d-flex align-items-center negrita my-0 btn-position mobile"
        @click="$emit('show')"
        >{{ `<-` }}</span
      >
      <template>
        <div class="row px-4 mt-2">
          <h3>{{ item.name != 'Cuenta' ? item.name : user.name }}</h3>
        </div>
        <p v-if="user_role" class="text_sm text-blue capitalize ml-4 mb-2">
          {{ user_role }}
        </p>
        <div class="item_box mb-6">
          <div v-for="info in info_item" :key="info.id">
            <template v-if="!info.sub_category">
              <span
                v-if="
                  info.path &&
                    (info.hasOwnProperty('category') ? info.category : true)
                "
                @click="$emit('show')"
              >
                <router-link
                  v-if="info.category"
                  :to="info.path"
                  class="item-link"
                  >{{ info.name }}
                  <span v-if="setLabels(info.name)" class="label">nuevo</span>
                </router-link>
                <router-link v-else :to="info.path" :class="{'item-link': info.name != 'Previsualizar', 'btn t-regular normal btn-select my-2 static ml-3': info.name == 'Previsualizar'}">{{ info.name }}
                  <!-- <span v-if="setLabels(info.name)" class="label">nuevo</span> -->
                </router-link>
              </span>
              <template v-else-if="info.action">
                <hr v-show="item.name == 'Cuenta'" class="my-3 mx-3" />
                <span class="item-link click" @click.prevent="info.action">{{
                  active_view == 'assigned_bots'
                    ? info.name == 'Eliminar'
                      ? 'Desvincular'
                      : info.name
                    : info.name
                }}</span>
              </template>
              <span v-else class="item-link text-disabled">{{
                info.name
              }}</span>
            </template>
            <template v-else>
              <span
                @click="showDropCategory(info)"
                :class="{
                  'item-link arrow': true,
                  'arrow-2': info.category == true,
                  'item-link-active': info.category == true,
                }"
                >{{ info.name }}</span
              >
              <!-- @closeDrop="$emit('show')" -->
              <SlideYUpTransition :duration="200">
                <SubMenuLvl2 v-show="info.category" :info="info" />
              </SlideYUpTransition>
            </template>
          </div>
        </div>
      </template>
    </section>
  </SlideXLeftTransition>
</template>

<script>
import { SlideXLeftTransition, SlideYUpTransition } from 'vue2-transitions';
import { mapState, mapActions } from 'vuex';
import SubMenuLvl2 from './SubMenuLvl2';
import dashboard_api from '@/dashboard_api.js';

export default {
  props: ['item'],

  components: {
    SlideXLeftTransition,
    SlideYUpTransition,
    SubMenuLvl2,
  },

  data() {
    return {
      user_items: [
        {
          name: 'Mi cuenta',
          path: '/profile',
        },
        {
          name: 'Facturación',
          path: '/facturacion',
        },
        {
          name: 'Notificaciones',
          path: '/notificaciones',
        },
        {
          name: 'Tutoriales',
          path: '/tutoriales',
        },
        {
          name: 'Tickets',
          path: '/tickets',
        },
        {
          name: 'Soporte',
          path: '/soporte',
        },
        {
          name: 'Incidencia',
          category: false,
          sub_category: true
        },
        // {
        //   name: 'Mejorar plan',
        //   path: '/dashboard/planes',
        // },
        {
          name: 'Cerrar sesión',
          path: '',
          action: this.logout,
        },
      ],
      dashboard: [
        {
          name: 'Dashboard',
          path: `/`,
        },
        {
          name: 'Métricas',
          path: `/metrics`,
        },
      ],
      chatbots: [
        {
          name: 'Editar',
          path: `/chatbot/edit`,
        },
        {
          name: 'Implementar',
          action: this.getShowImplement,
        },
        {
          name: 'Crear chatbot',
          path: '/chatbot/new',
        },
        {
          name: 'Previsualizar',
          path: '/preview',
        },
        {
          name: 'Eliminar',
          action: this.deleteChatbot,
        },
      ],
      databot_center: [
        {
          name: 'Interacciones',
          category: false,
          sub_category: true,
        },
        {
          name: 'Automatizaciones',
          path: '/automatizaciones',
          category: true,
          sub_category: false,
        },
        {
          name: 'Documentos',
          path: '/documento-conocimiento',
          category: true,
          sub_category: false,
        },
        {
          name: 'Configuración de IA',
          path: '/chatbot-center/prompt-manager',
          category: true,
          sub_category: false,
        }
      ],
      whatsapp: [
        {
          name: 'WhatsApp API',
          path: '/whatsapp-business',
        },
        {
          name: 'Widget',
          path: '/widgetWhatsapp',
        },
        {
          name: 'Estadísticas',
          path: '/whatsapp-estadisticas',
        },
      ],
      loadingComplete: false,
    };
  },
  computed: {
    ...mapState(['logged', 'user', 'selected_bot', 'active_view', 'ownerBots', 'user_role']),

    filteredUserItems() {
      let restrictions = ['Facturación', 'Mejorar plan'];

      return this.user_role == 'agente'
        ? this.user_items.filter(el => !restrictions.includes(el.name))
        : this.user_items;
    },
    info_item() {
      switch (this.item.name) {
        case 'Cuenta':
          return this.filteredUserItems;
          break;
        case 'Dashboard':
          return this.dashboard;
          break;
        case 'Chatbot':
          return this.chatbots;
          break;
        case 'Databot Center':
          return this.databot_center;
          break;
        case 'WhatsApp':
          return this.whatsapp;
          break;
        case 'Incidencias':
          return this.incidents;
          break;
        default:
          return [];
      }
    },
  },
  methods: {
    ...mapActions(['logout']),

    setLabels(name) {
      // Para mostrar etiqueta de item nuevo en el sidebar
      let views = ['Métricas', 'Configuración de IA'];

      return views.includes(name);
    },
    showDropCategory(item) {
      item.category == false ? item.category = true : item.category = false;
    },
    getShowImplement() {
      this.$emit('getShowImplement', true);
    },
    deleteChatbot() {
      const self = this;
      const owner = this.active_view == 'my_bots';

      if (self.ownerBots.length > 1) {
        self
          .$swal({
            title: `${
              owner ? '¿Eliminar chatbot?' : '¿Desvincular del chatbot?'
            }`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Cancelar',
            cancelButtonText: `${owner ? 'Eliminar' : 'Devincular'}`,
          })
          .then(result => {
            // invertido para que el usuario no borre el bot
            if (!result.isConfirmed) {
              if (self.active_view != 'assigned_bots') {
                // Se cancela el plan primero, si no da error se prosigue con eliminar el bot
                if (self.selected_bot.client_subscription == null) {
                  dashboard_api
                    .delete(`/bot/${self.selected_bot.id}`)
                    .then(data => {
                      self.$swal({
                        icon: 'success',
                        title: 'El chatbot se eliminó correctamente.',
                        timer: 1000,
                        showConfirmButton: false,
                      });
                      let newBots = self.ownerBots;

                      newBots.forEach((bot, index, array) => {
                        if (bot.id == self.selected_bot.id) {
                          array.splice(index, 1);
                        }
                        self.$store.commit('setOwnerBots', newBots);
                        self.$store.commit('setSelectedBot', self.ownerBots[0]);
                      });
                    });
                } else {
                  // console.log('self.selected_bot.client_subscription.id', self.selected_bot.client_subscription.id);
                  dashboard_api
                    .post('/flow_requests/cancel_subscription', {
                      id: self.selected_bot.client_subscription.id,
                    })
                    .then(cancelSubscriptionResponse => {
                      if (cancelSubscriptionResponse.status == 800) {
                        self.$swal({
                          icon: 'error',
                          title:
                            'El chatbot se no se pudo eliminar correctamente, por favor contacta a soporte.',
                        });
                      } else {
                        dashboard_api
                          .delete(`/bot/${self.selected_bot.id}`)
                          .then(data => {
                            self.$swal({
                              icon: 'success',
                              title: 'El chatbot se eliminó correctamente.',
                              timer: 1000,
                              showConfirmButton: false,
                            });
                            let newBots = self.ownerBots;

                            newBots.forEach((bot, index, array) => {
                              if (bot.id == self.selected_bot.id) {
                                array.splice(index, 1);
                              }
                            });
                            self.$store.commit('setOwnerBots', newBots);
                            self.$store.commit(
                              'setSelectedBot',
                              self.ownerBots[0],
                            );
                          });
                      }
                    })
                    .catch(error => console.error('error > ', error));
                }
              } else {
                // console.log("bot asignado");
                dashboard_api
                  .delete(
                    `/userbot/unlink_bot/${self.user.id}/${self.selected_bot.id}`,
                  )
                  .then(data => {
                    self.$swal({
                      icon: 'success',
                      title: 'El Chatbot se desvinculó exitosamente',
                    });
                    self.bots.forEach((bot, index, array) => {
                      if (bot.id == self.selected_bot.id) {
                        array.splice(index, 1);
                      }
                    });
                  });
                location.reload();
              }
            }
          });
      } else {
        self.$swal({
          icon: 'error',
          title: 'Tu cuenta debe tener al menos un chatbot',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-menu {
  position: absolute;
  padding: 1.25rem 0;
  top: 0;
  width: 200px;
  height: 100vh;
  right: -200px;
  border-right: 1px solid #e8e8e8;
  background-color: #fff;
  z-index: 50;

  .item_box {
    margin-top: 2.5rem;
  }
  h3 i {
    cursor: pointer;
    &:hover {
      transition: 0.5s;
      opacity: 0.5;
    }
  }
  .text-state {
    width: fit-content;
  }
  .btn-dash-sm {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1rem;
    right: -1.65rem;
    margin: 0 auto;
    border: 1px solid #333;
    background-color: #181818;
    border-radius: 0 0.25rem 0.25rem 0;
    padding: 1rem 0.5rem;
    font-size: 12px;
    color: #b2b2b2;
    cursor: pointer;

    i {
      color: #fff;
      margin: 0;
    }
  }
  .btn-position {
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    width: 40px;
    height: 40px;
    z-index: 200;

    @media (max-width: 375px) {
      left: 1rem;
    }
  }
}
.desktop {
  @media (max-width: 1024px) {
    display: none !important;
  }
}
.mobile {
  @media (min-width: 1025px) {
    display: none !important;
  }
}
.change-mobile {
  @media (max-width: 1024px) {
    position: absolute;
    padding: 3rem 1rem 2rem;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    z-index: 101;

    .btn-dash-sm {
      position: absolute;
      top: 1rem;
      left: 1.5rem;
      width: 40px;
      height: 40px;
      z-index: 200;

      @media (max-width: 375px) {
        left: 1rem;
      }
    }
  }
  .item-link {
    padding: .75rem 1.5rem;

    &-active {
      color: #181818 !important;
      background-color: #F8FBFF;
    }
    &:hover {
      background-color: #F8FBFF;
    }
  }
  .label {
    font-size: 12px;
    border-radius: .5rem;
    color: #2981EF;
    border: 1px solid #2981EF;
    background-color: #fff;
    padding: .025rem .5rem;
  }
}
</style>
