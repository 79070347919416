<template>
  <div class="auth-nav">
    <div class="label-box">
      <router-link to="/">
        <img class="logo" src="/img/brand/0-databot-negro.svg" />
      </router-link>
      <template v-if="route.path == '/registro'">
        <div class="d-flex align-item-center">
          <BulletSteps :step="registerData.step" />
          <span class="btn-select" @click="dontLeaveRegister">Inicia sesión -></span>
        </div>
      </template>
      <template v-else>
        <router-link
          class="btn-select"
          :to="{
            path: '/registro',
            query: {
              shop: route.query.shop,
              access_token: route.query.access_token,
              token_access: token,
              shop_domain: route.query.shop_domain || platform_name,
              code: route.query.code
            }
          }"
        >Regístrate -></router-link>
      </template>
    </div>
  </div>
</template>

<script>
import BulletSteps from "@/views/V2/Auth/components/BulletSteps";
import { mapState, mapMutations } from "vuex";

export default {
  props: ["route", "token", "platform_name"],
  components: {
    BulletSteps
  },
  computed: {
    ...mapState(['registerData'])
  },
  methods: {
    ...mapMutations(["cleanRegisterData", "cleanCheckoutData", "logout"]),

    dontLeaveRegister() {
      if (this.registerData.step == 5) return this.$router.push({ path: '/dashboard' });

      if (this.registerData.step != 1) {
        this.$swal({
          icon: "info",
          title: "¿Abandonar registro?",
          text: "Falta muy poco para terminar de crear tu cuenta. ¿Seguro que quieres salir?",
          showConfirmButton: true,
          confirmButtonText: 'Salir',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
        }).then(el => {
          if (el.isConfirmed) {
            this.$router.push({
              path: '/login',
              query: {
                shop: this.$route.query.shop,
                access_token: this.$route.query.access_token,
                token_access: this.token,
                shop_domain: this.$route.query.shop_domain || this.platform_name,
                code: this.$route.query.code
              }
            });
            this.cleanRegisterData();
            this.cleanCheckoutData();
            this.logout();
          };
        });
      } else {
        this.$router.push({
          path: '/login',
          query: {
            shop: this.$route.query.shop,
            access_token: this.$route.query.access_token,
            token_access: this.token,
            shop_domain: this.$route.query.shop_domain || this.platform_name,
            code: this.$route.query.code
          }
        });
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-nav {
  padding: 1rem 2rem;

  @media (max-width: 640px) {
    padding: 1rem 1.5rem;
  }
  .logo {
    width: 100%;
    
    @media (max-width: 640px) {
      width: 120px;
    }
  }
}
</style>