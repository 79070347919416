var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"auth-layout"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"auth-nav"},[_c('div',{staticClass:"label-box"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":"img/brand/0-databot-negro.svg"}})]),(_vm.$route.path == '/registro')?[_c('router-link',{staticClass:"btn btn-base sm",attrs:{"to":{
              path: '/login',
              query: {
                shop: _vm.$route.query.shop,
                access_token: _vm.$route.query.access_token,
                token_access: _vm.token,
                shop_domain: _vm.$route.query.shop_domain || _vm.platform_name,
                code: _vm.$route.query.code
              }
            }}},[_vm._v("Inicia sesión ")])]:[_c('router-link',{staticClass:"btn btn-base sm",attrs:{"to":{
              path: '/registro',
              query: {
                shop: _vm.$route.query.shop,
                access_token: _vm.$route.query.access_token,
                token_access: _vm.token,
                shop_domain: _vm.$route.query.shop_domain || _vm.platform_name,
                code: _vm.$route.query.code
              }
            }}},[_vm._v("Regístrate")])]],2)]),_c('SlideYUpTransition',{attrs:{"mode":"out-in","origin":"center top"}},[_c('div',{staticClass:"col-lg-12"},[_c('router-view')],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }