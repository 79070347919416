// modulo para gestion de entorno de Plataformas ecommerce (Jumpseller, Shopify, etc)
const state = () => ({
  platform: null, // shopify - jumpseller - vtex etc
  platformParameters: {}, // query parameters from platform
  shopifyAppInstance: null, // shopify app bridge instance
});

const getters = {};
const actions = {};
const mutations = {
  setPlatform(state, payload) {
    state.platform = payload;
  },
  setPlatformParameters(state, payload) {
    state.platformParameters = payload;
  },
  setShopifySession(state, data) {
    state.shopifySession = data;
  },
  setShopifyAppInstance(state, data) {
    state.shopifyAppInstance = data;
  },
};

const namespaced = true;

export default { getters, actions, mutations, state, namespaced };
