import api from '@/services/api/dt_nodes.js';

const state = () => ({
  dtIntents: [],
});

const getters = {};
const actions = {
  async byIntentName({ commit }, { name, botId, template } = {}) {
    let resp = (await api.byIntentName(name, botId, template)).data;
    return resp;
  },
};
const mutations = {};

const namespaced = true;

export default { getters, actions, mutations, state, namespaced };
