import axios from 'axios';
import store from '@/store/store.js';
import { localStorageGetItem } from '@/utils/utils';

axios.interceptors.request.use(
  (config) => {
    const urlsExcludedForBearerHeader = [
      '/auth/sign_in',
      '/auth',
      '/password/forgot',
      '/password/reset',
    ];
    if (urlsExcludedForBearerHeader.indexOf(config.url) === -1) {
      config.headers['access-token'] =
        localStorageGetItem('token') || store.state.authModule['access-token'];
      config.headers['token-type'] =
        localStorageGetItem('tokenType') ||
        store.state.authModule['token-type'];
      config.headers['accept'] = 'application/vnd.v3+json';
      config.headers.client =
        localStorageGetItem('client') || store.state.authModule.client;
      config.headers.expiry =
        localStorageGetItem('expiry') || store.state.authModule.expiry;
      config.headers.uid =
        localStorageGetItem('uid') || store.state.authModule.uid;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
  },
);
