
const permissions = {
  owner: [
    {
      module_name: "Dashboard",
      view_name: "dashboard",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Chatbot",
      view_name: "chatbot-edit",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Nuevo Chatbot",
      view_name: "chatbot-new",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Skill Base",
      view_name: "skill-base",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Preguntas frecuentes",
      view_name: "faqs",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Automatizaciones",
      view_name: "automatizaciones",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Menú inicial",
      view_name: "initial-menu",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Historial de conversaciones",
      view_name: "conversation-history",
      permissions: ["read", "export"]
    },
    {
      module_name: "CRM",
      view_name: "crm-clients",
      permissions: ["read", "edit", "export", "upload", "add", "delete", "send-wsp"]
    },
    {
      module_name: "Databot live",
      view_name: "databot-live",
      permissions: ["read", "edit", "add", "delete", "send-wsp", "shunt-agent"]
    },
    {
      module_name: "Whatsapp Api",
      view_name: "whatsapp-api",
      permissions: ["read", "add", "send-wsp"]
    },
    {
      module_name: "Widget WhatsApp",
      view_name: "widget-whatsapp",
      permissions: ["read", "edit", "add", "delete"]
    },
    {
      module_name: "Mensajes de plantilla",
      view_name: "whatsapp-estadisticas",
      permissions: ["read"]
    },
    {
      module_name: "Agentes",
      view_name: "agentes",
      permissions: ["read", "edit", "add", "delete"]
    },
    {
      module_name: "Nuevo agente",
      view_name: "new-agent",
      permissions: ["read", "add"]
    },
    {
      module_name: "Editar agente",
      view_name: "edit-agent",
      permissions: ["read", "edit"]
    },
    {
      module_name: "Asociar agente",
      view_name: "associate-agent",
      permissions: ["read", "add"]
    },
    {
      module_name: "Roles de agente",
      view_name: "role-list",
      permissions: ["read", "edit"]
    },
    {
      module_name: "Nuevo Rol",
      view_name: "role-new",
      permissions: ["read", "edit" , "add"]
    },
    {
      module_name: "Integraciones",
      view_name: "integraciones-view",
      permissions: ["read", "edit", "add", "delete"]
    },
    {
      module_name: "Planes",
      view_name: "dashboard-planes",
      permissions: ["read", "edit", "add", "delete"]
    },
    {
      module_name: "Planes Check-Out",
      view_name: "dashboard-checkout",
      permissions: ["read", "edit", "add", "delete"]
    },
    {
      module_name: "Facturación",
      view_name: "facturacion",
      permissions: ["read", "add", "delete"]
    },
    {
      module_name: "Tickets",
      view_name: "tickets",
      permissions: ["read", "add"]
    },
    {
      module_name: "Seguimiento de Incidencias",
      view_name: "seguimiento-view",
      permissions: ["read", "edit"]
    },
  ],
  admin: [
    {
      module_name: "Dashboard",
      view_name: "dashboard",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Chatbot",
      view_name: "chatbot-edit",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Nuevo Chatbot",
      view_name: "chatbot-new",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Skill Base",
      view_name: "skill-base",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Preguntas frecuentes",
      view_name: "faqs",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Automatizaciones",
      view_name: "automatizaciones",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Menú inicial",
      view_name: "initial-menu",
      permissions: ["read", "edit", "delete", "add"]
    },
    {
      module_name: "Historial de conversaciones",
      view_name: "conversation-history",
      permissions: ["read", "export"]
    },
    {
      module_name: "CRM",
      view_name: "crm-clients",
      permissions: ["read", "edit", "export", "upload", "add", "delete", "send-wsp"]
    },
    {
      module_name: "Databot live",
      view_name: "databot-live",
      permissions: ["read", "edit", "add", "delete", "send-wsp", "shunt-agent"]
    },
    {
      module_name: "Whatsapp Api",
      view_name: "whatsapp-api",
      permissions: ["read", "add", "send-wsp"]
    },
    {
      module_name: "Widget WhatsApp",
      view_name: "widget-whatsapp",
      permissions: ["read", "edit", "add", "delete"]
    },
    {
      module_name: "Mensajes de plantilla",
      view_name: "whatsapp-estadisticas",
      permissions: ["read"]
    },
    {
      module_name: "Agentes",
      view_name: "agentes",
      permissions: ["read"]
    },
    {
      module_name: "Nuevo agente",
      view_name: "new-agent",
      permissions: ["read"]
    },
    {
      module_name: "Editar agente",
      view_name: "edit-agent",
      permissions: ["read"]
    },
    {
      module_name: "Asociar agente",
      view_name: "associate-agent",
      permissions: ["read"]
    },
    {
      module_name: "Roles de agente",
      view_name: "role-list",
      permissions: []
    },
    {
      module_name: "Nuevo Rol",
      view_name: "role-new",
      permissions: []
    },
    {
      module_name: "Integraciones",
      view_name: "integraciones-view",
      permissions: ["read", "edit", "add", "delete"]
    },
    {
      module_name: "Planes",
      view_name: "dashboard-planes",
      permissions: ["read"]
    },
    {
      module_name: "Planes Check-Out",
      view_name: "dashboard-checkout",
      permissions: ["read"]
    },
    {
      module_name: "Facturación",
      view_name: "facturacion",
      permissions: []
    },
    {
      module_name: "Tickets",
      view_name: "tickets",
      permissions: ["read", "add"]
    },
    {
      module_name: "Seguimiento de Incidencias",
      view_name: "seguimiento-view",
      permissions: ["read", "edit"]
    },
  ],
  agent: [
    {
      module_name: "Dashboard",
      view_name: "dashboard",
      permissions: ["read"]
    },
    {
      module_name: "Chatbot",
      view_name: "chatbot-edit",
      permissions: []
    },
    {
      module_name: "Nuevo Chatbot",
      view_name: "chatbot-new",
      permissions: []
    },
    {
      module_name: "Skill Base",
      view_name: "skill-base",
      permissions: []
    },
    {
      module_name: "Preguntas frecuentes",
      view_name: "faqs",
      permissions: []
    },
    {
      module_name: "Automatizaciones",
      view_name: "automatizaciones",
      permissions: []
    },
    {
      module_name: "Menú inicial",
      view_name: "initial-menu",
      permissions: []
    },
    {
      module_name: "Historial de conversaciones",
      view_name: "conversation-history",
      permissions: ["read"]
    },
    {
      module_name: "CRM",
      view_name: "crm-clients",
      permissions: ["read", "edit", "upload", "add", "delete", "send-wsp"]
    },
    {
      module_name: "Databot live",
      view_name: "databot-live",
      permissions: ["read", "edit", "add", "delete", "send-wsp", "shunt-agent"]
    },
    {
      module_name: "Whatsapp Api",
      view_name: "whatsapp-api",
      permissions: ["read", "add", "send-wsp"]
    },
    {
      module_name: "Widget WhatsApp",
      view_name: "widget-whatsapp",
      permissions: []
    },
    {
      module_name: "Mensajes de plantilla",
      view_name: "whatsapp-estadisticas",
      permissions: []
    },
    {
      module_name: "Agentes",
      view_name: "agentes",
      permissions: []
    },
    {
      module_name: "Nuevo agente",
      view_name: "new-agent",
      permissions: []
    },
    {
      module_name: "Editar agente",
      view_name: "edit-agent",
      permissions: []
    },
    {
      module_name: "Asociar agente",
      view_name: "associate-agent",
      permissions: []
    },
    {
      module_name: "Roles de agente",
      view_name: "role-list",
      permissions: []
    },
    {
      module_name: "Nuevo Rol",
      view_name: "role-new",
      permissions: []
    },
    {
      module_name: "Integraciones",
      view_name: "integraciones-view",
      permissions: []
    },
    {
      module_name: "Planes",
      view_name: "dashboard-planes",
      permissions: []
    },
    {
      module_name: "Planes Check-Out",
      view_name: "dashboard-checkout",
      permissions: []
    },
    {
      module_name: "Facturación",
      view_name: "facturacion",
      permissions: []
    },
    {
      module_name: "Tickets",
      view_name: "tickets",
      permissions: ["read", "add"]
    },
    {
      module_name: "Seguimiento de Incidencias",
      view_name: "seguimiento-view",
      permissions: ["read", "edit"]
    },
  ]
};

export default permissions;