import { localStorageSetItem } from "@/utils/utils";

const state = () => ({
  "access-token": "",
  "token-type": "",
  client: "",
  expiry: "",
  uid: "",
});

const getters = {};
const actions = {};
const mutations = {
  credentialsStorage(state, payload) {
    state["access-token"] = payload["access-token"];
    state["token-type"] = payload["token-type"];
    state.client = payload.client;
    state.expiry = payload.expiry;
    state.uid = payload.uid;
    localStorageSetItem("token", payload["access-token"]);
    localStorageSetItem("tokenType", payload["token-type"]);
    localStorageSetItem("client", payload.client);
    localStorageSetItem("expiry", payload.expiry);
    localStorageSetItem("uid", payload.uid);
  },
};

const namespaced = true;

export default { getters, actions, mutations, state, namespaced };
