<template>
  <section class="menu-box">
    <div class="sidebar">
      <img
        @click="setShowSidebarMobile(!showSidebarMobile)"
        class="btn-menu i-btn px-3 py-2 mobile"
        src="/img/icons/30-menu.svg"
        alt=""
      />
      <div v-if="selected_bot" class="box">
        <div class="d-flex flex-column align-items-center">
          <div class="mt-3 p-relative">
            <div
              v-for="item in filteredItems"
              :key="item.id"
              @click="showSubMenu(item)"
              class="p-relative"
            >
              <span
                v-if="(item.path == '/databot-live' || item.path=='/databot-live-2') && new_message"
                class="notification_dot"
              ></span>
              <router-link
                v-if="item.path?.length > 0"
                :class="{
                  'btn-item': true,
                  'btn-item_active':
                    selected_item.name == item.name,
                }"
                :to="selected_bot.activated != false ? item.path : ''"
              >
                <img
                  :src="item.icon"
                  :alt="item.name"
                />
                <span v-show="!showSub" class="tooltip_text-sm">{{ item.name }}</span>
              </router-link>
              <span
                v-else
                :class="{
                  'btn-item': true,
                  'mb-5 mt-0': item.name == 'Cuenta',
                  'btn-item_active':
                    selected_item.name == item.name,
                }"
              >
                <img
                  :class="{ 'databot-iso': item.name == 'Cuenta' }"
                  :src="item.name == 'Cuenta' && user?.user_avatar ? user.user_avatar : item.icon"
                  :alt="item.name"
                />
                <span v-show="!showSub" class="tooltip_text-sm">{{ item.name }}</span>
              </span>
            </div>
          </div>
        </div>
        <SubMenu
          v-if="selected_bot"
          v-show="showSub"
          :key="active_view"
          :item="selected_item"
          @show="showSub = false"
          @getShowImplement="show_implement = $event"
        />
        <Implementacion
          :show="show_implement"
          :bot_id="selected_bot.id"
          :bot_token="selected_bot.token"
          @close="show_implement = false"
        />
      </div>
    </div>
    <MobileMenu
      @close="setShowSidebarMobile($event)"
      @getShowImplementMobile="show_implement = $event"
      @showMobile="showSub = false"
      :user="user"
      :showSub="showSub"
      :show="showSidebarMobile"
      :active_view="active_view"
      :items="filteredItems"
    />
  </section>
</template>

<script>
import Implementacion from '@/components/Implementacion';
import { mapMutations, mapState } from 'vuex';
import dashboard_api from '../../dashboard_api.js';
import SubMenu from './SubMenu';
import MobileMenu from '@/components/mobile-menu/MobileMenu';

export default {
  name: 'sidebar',

  components: {
    SubMenu,
    Implementacion,
    MobileMenu,
  },
  data() {
    return {
      url_shop: '',
      show_implement: false,
      plans: [],
      showSub: false,
      selected_item: {},
      items: [
        {
          name: 'Cuenta',
          module: 'profile',
          path: '',
          icon: '/img/icons/dashboard/0-user-profile.svg',
          sub_menu: true,
        },
        {
          name: 'Dashboard',
          module: 'dashboard',
          path: '',
          icon: '/img/icons/dashboard/1-dashboard.svg',
          sub_menu: true,
        },
        {
          name: 'Chatbot',
          module: 'chatbot',
          path: '',
          icon: '/img/icons/dashboard/2-chatbot.svg',
          sub_menu: true,
        },
        {
          name: 'Databot Center',
          module: 'skill-base',
          path: '',
          icon: '/img/icons/dashboard/3-skill.svg',
          sub_menu: true,
        },
        {
          name: 'Historial de conversaciones',
          module: 'conversation-history',
          path: '/historial-conversaciones',
          icon: '/img/icons/dashboard/4-conversaciones.svg',
          sub_menu: false,
        },
        {
          name: 'Clientes',
          module: 'crm-clients',
          path: '/clients',
          icon: '/img/icons/dashboard/5-leads.svg',
          sub_menu: false,
        },
        {
          name: 'Databot Live',
          module: 'databot-live',
          path: '/databot-live',
          icon: '/img/icons/dashboard/6-dlive.svg',
          sub_menu: false,
        },
        {
          name: 'WhatsApp',
          module: 'whatsapp-api',
          path: '',
          icon: '/img/icons/dashboard/7-whatsapp.svg',
          sub_menu: true,
        },
        {
          name: 'Agentes',
          module: 'agentes',
          path: '/agentes',
          icon: '/img/icons/dashboard/8-agentes.svg',
          sub_menu: false,
        },
        {
          name: 'Integraciones',
          module: 'integraciones-view',
          path: '/integraciones',
          icon: '/img/icons/dashboard/9-integraciones.svg',
          sub_menu: false,
        }
      ],
    };
  },

  mounted() {
    this.getPlans();
    this.itemfilterValidation();
    // just for test accounts, aditional options
    if (
      this.$store.state.user &&
      this.$store.state.testAccounts.some(
        (el) => el === this.$store.state.user.email,
      )
    ) {
      this.items.push({
        name: 'Configuración',
        path: '/settings',
        icon: '/img/icons/dashboard/12-settings.svg',
        sub_menu: false,
      });
    }
  },
  watch: {
    active_view(val) {
      this.itemfilterValidation();
    },
    user: {
      immediate: true,
      handler(val) {
        this.validatePlatform(val.id);
      },
    },
  },
  computed: {
    ...mapState([
      'user',
      'active_view',
      'selected_bot',
      'platform_name',
      'new_message',
      'showSidebarMobile',
      'role_permission',
      'user_role',
    ]),
    filteredItems() {
      // let filter_dlive = [64, 7, 2588, 2525, 2351, 1764];

      if (this.selected_bot.is_databot_live_3) {
        let dlive_3 = this.items.find(el => el.name == 'Databot Live');
        let conversations_2 = this.items.find(el => el.name == 'Historial de conversaciones');

        dlive_3.path = '/databot-live-2';
        conversations_2.path = '/historial-conversaciones-2';
      }

      // if (filter_dlive.includes(this.selected_bot.id)) {
      //   let dlive_3 = this.items.find(el => el.name == 'Databot Live');
      //   let conversations_2 = this.items.find(el => el.name == 'Historial de conversaciones');

      //   dlive_3.path = '/databot-live-2';
      //   conversations_2.path = '/historial-conversaciones-2';
      // };
      if (this.user_role.length > 0) {
        if (this.user_role != 'agente') return this.items;
        else {
          let agent_items = this.items.filter(el => this.role_permission.modules.includes(el.module));
          // No está dentro de los modulos del back
          agent_items.unshift({
            name: 'Cuenta',
            module: 'profile',
            path: '',
            icon: '/img/icons/dashboard/0-user-profile.svg',
            sub_menu: true,
          },)
          return agent_items;
        }
      };
    },
  },
  methods: {
    ...mapMutations([
      'getPlatformName',
      'setNewMessage',
      'setShowSidebarMobile'
    ]),
    resetNewMessage(name) {
      name == 'Databot Live' ? this.setNewMessage(false) : '';
    },
    async validatePlatform(user_id) {
      if (!this.platform_name) {
        this.url_shop = (
          await dashboard_api.get(`user_data/${user_id}`)
        ).data.origin_ecommerce.url_origin;

        let origins = [{ name: 'shopify' }, { name: 'jumpseller' }];

        if (this.url_shop && this.url_shop.length > 0) {
          this.platform = origins.find((el) =>
            this.url_shop.includes(el.name),
          ).name;

          console.log('entre', this.platform);
        } else this.platform = 'databot';
        this.getPlatformName(this.platform);
      };
      this.itemfilterValidation();
    },
    itemfilterValidation() {
      if (this.platform_name == 'shopify') {
        if (this.user_role != 'agente') {
          let item = this.items.findIndex(el => el.name == 'Integraciones');
          // console.log('item->', item);
          this.items.splice(item, 1);
        } else {
          this.items.push({
            name: 'Integraciones',
            path: '/integraciones',
            icon: '/img/icons/dashboard/9-integraciones.svg',
            sub_menu: false,
          });
        }
      } else this.items;
    },
    blockDesactivatedBot() {
      this.$swal({
        icon: 'info',
        title: 'Activa tu chatbot con un plan.',
        text: 'Para seguir ocupando este chatbot debes asignar un plan pagado.',
      });
      // TODO Sistema de planes con flow deprecado
      // this.$router.push({
      //   name: 'dashboard-planes',
      //   query: { seccion: 'activar', bot_id: this.selected_bot.id },
      // });
    },
    showSubMenu(item) {
      if (this.selected_bot && this.selected_bot.activated == false) this.blockDesactivatedBot();
      else {
        this.selected_item = item;

        if (this.showSub == false) {
          if (item.sub_menu == true) this.showSub = true;
          else this.showSub = false;
        } else if (item.sub_menu == true) this.showSub = true;
        else this.showSub = false;
      };
      this.resetNewMessage(item.name);
    },
    async getPlans() {
      if (this.user?.id) {
        this.plans = (
          await dashboard_api.get(
            `/client_subscriptions/by_user_id/${this.user.id}/false`,
          )
        ).data;
        // console.log('this.plans sidebar', this.plans);
      }
    },
    notifications() {
      const self = this;

      webpushr('fetch_id', (sid) => {
        if (sid) {
          dashboard_api
            .post('/push_subscribers', {
              push_subscriber: {
                user_id: self.user.id,
                sid,
                active: true,
              },
            })
            .then((response) => {
              // console.log("response -> ", response);
            })
            .catch((error) => {
              console.log('error -> ', error);
            });
        } else {
          console.log('no sid');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-box {
  height: 100%;

  .sidebar {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    transition: 0.5s ease all;
    background-color: #181818;
    animation-name: first-right-slide;
    animation-duration: 0.5s;
    z-index: 201;

    @media (max-width: 1024px) {
      opacity: 0;
      visibility: hidden;
    }
    .box {
      position: sticky;
      top: 0;
    }
    .databot-iso {
      object-fit: cover;
      object-position: center;
      margin: 1.25rem auto !important;
      border-radius: 50rem;
      width: 45px !important;
      height: 45px !important;
      align-items: center;
      transition: 0.5s;
      cursor: pointer;

      &:hover {
        transition: 0.5s;
        transform: scale(1.1);

        + .tooltip_text-sm {
          visibility: visible;
          transition: 0.25s;
          opacity: 1;
          z-index: 150 !important;
        }
      }
    }
    .btn-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      transition: 0.5s;
      margin: 0.75rem auto;
      border-radius: 20rem;
      cursor: pointer;

      img {
        width: 1.4rem;
        transition: 0.5s;
      }
      &:hover {
        transition: 0.5s;
        background-color: #333;

        .tooltip_text-sm {
          visibility: visible;
          transition: 0.25s;
          opacity: 1;
          z-index: 150 !important;
        }
        img {
          transition: 0.5s;
          transform: scale(1.1);
        }
      }
    }
    .btn-item_active {
      padding: 0.5rem;
      border-radius: 50%;
      transition: 0.5s;
      background-color: #333;
    }
    .btn-update {
      width: 1.75rem !important;
    }
  }
  .btn-menu {
    display: none;
    position: absolute;
    top: 0.75rem;
    left: 100%;
    right: 0;
    color: #fff;
    font-size: 1rem;
    width: 30px;
    height: 50px;
    padding: 0.5rem;
    border-radius: 0 0.5rem 0.5rem 0;
    background-color: #181818;
    z-index: 15;

    @media (max-width: 640px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
  .notification_dot {
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    border-radius: 2rem;
    background-color: #f5365c;
    top: 0;
    right: 0;
    z-index: 5;
    border: 2px solid #181818;
  }
}
</style>
