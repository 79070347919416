import api from '@/services/api_dashboard/leads';
import { handleError, buildQueryWithPagination } from '@/utils/utils.js';

const module = {
  namespaced: true,
  state: {
    leads: [],
    total: 0,
    totalPages: 0,
  },
  actions: {
    list({ commit }, query) {
      let finalQuery = buildQueryWithPagination(query);
      return new Promise((resolve, reject) => {
        api
          .list(finalQuery)
          .then((response) => {
            commit('list', response.data);
            commit('totalItems', response.data.totalDocs);
            commit('totalPages', response.data.totalPages);
            resolve(response.data);
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    listOne({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        api
          .listOne(id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    create({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .create(data)
          .then((res) => {
            commit('create', res.data);
            resolve(res.data);
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    update({ commit }, { id, data }) {
      return new Promise((resolve, reject) => {
        api
          .update(id, data)
          .then((res) => {
            commit('update', {
              id,
              data: res.data,
            });
            resolve(res.data);
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(id)
          .then(() => {
            commit('delete', id);
            resolve();
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    async showByEmailOrPhoneOrRut({ commit }, { botId, phone, email, rut }) {
      try {
        const res = await api.showByEmailOrPhoneOrRut(botId, {
          phone,
          email,
          rut,
        });
        return res.data;
      } catch (error) {
        console.log('Not found lead: ', error);
      }
    },
  },
  mutations: {
    list(state, data) {
      state.leads = data;
    },
    totalItems(state, data) {
      state.total = data;
    },
    totalPages(state, data) {
      state.totalPages = data;
    },
    create(state, data) {
      state.leads.push(data);
    },
    update(state, { id, data }) {
      let indexToUpdate = state.leads.findIndex((member) => member._id == id);
      state.leads.splice(indexToUpdate, 1, {
        ...data,
      });
    },
    delete(state, id) {
      let indexToDelete = state.leads.findIndex((member) => member._id == id);
      state.leads.splice(indexToDelete, 1);
      state.total -= 1;
    },
  },
  getters: {},
};

export default module;
