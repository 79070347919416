import axios from 'axios';
import environment from '@/environment.js';

export default {
  list() {
    return axios.get(`${environment.URL_MICRO_CONVERSATIONS}/conversations`);
  },
  update(id, payload) {
    return axios.put(
      `${environment.URL_MICRO_CONVERSATIONS}/conversations/${id}`,
      payload,
    );
  },
  create(payload) {
    return axios.post(
      `${environment.URL_MICRO_CONVERSATIONS}/conversations`,
      payload,
    );
  },
  delete(id) {
    return axios.delete(
      `${environment.URL_MICRO_CONVERSATIONS}/conversations/${id}`,
    );
  },
  countStates(botId, userId, { category, tagsLead } = {}) {
    // if (category && category.length > 0) {
    //   category = category[0]; // just take first category
    // }
    let baseUrl = `${environment.URL_MICRO_CONVERSATIONS}/conversations/count_states/${botId}/${userId}`;
    let payload = {};
    if (tagsLead) {
      payload.tags_lead = tagsLead;
    }
    if (category) {
      payload.category = category;
    }
    return axios.post(baseUrl, payload);
  },
  filterConversations(
    botId,
    agentId,
    filters = {},
    { items = 15, page = 1 } = {},
  ) {
    // // check if contain category
    // if (filters.category && filters.category.length > 0) {
    //   filters.category = filters.category[0]; // just take first category
    // }
    return axios({
      method: 'POST',
      url: `${environment.URL_MICRO_CONVERSATIONS}/conversations/filter_conversation`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { filters, items, page },
    });
  },
  searchConversations(bot_id, search_text, items = 15, page = 1, archived) {
    return axios({
      method: 'POST',
      url:`${environment.URL_MICRO_CONVERSATIONS}/conversations/by_lead_data`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { bot_id, search_text, items, page , archived}
    })
  }
};
