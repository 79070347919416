<template>
  <section class="loading">
    <!-- src="/img/icons/Ellipsis-2.svg" -->
    <img
      src="/img/icons/0-loading.gif"
      alt="loading"
    />
  </section>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fafafa;
  transition: .5s;

  img {
    width: 100px;
  }
}
</style>
