import axios from 'axios';
import environment from '@/environment.js';

export default {
  skillCreate(payload) {
    return axios.post(
      `${environment.DATABOT_TEMPLATES}/api/client/skill/create`,
      payload,
    );
  },
  skillUploadLLM(payload) {
    return axios.post(
      `${environment.RASA_URL}/api/assistants/upload_skill_base`,
      payload,
    );
  }
};
