import api from '@/services/api/dt_client_entities.js';

const state = () => ({
  dtClientEntities: [],
});

const getters = {};
const actions = {
  async entityCopyTemplate({ commit }, payload) {
    let resp = (await api.entityCopyTemplate(payload)).data;
    return resp;
  },
  async listByBotId({ commit }, botId) {
    let resp = (await api.listByBotId(botId)).data;
    return resp;
  },
  async deleteManyTemplateEntities({ commit }, { bot_id, template } = {}) {
    let resp = (await api.deleteManyTemplateEntities(bot_id, template)).data;
    return resp;
  }
};
const mutations = {
  setAssistant(state, payload) {
    state.assistant = payload;
  },
};

const namespaced = true;

export default { getters, actions, mutations, state, namespaced };
