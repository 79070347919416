/**
 * @description Este modulo sirve para crear filtros personalizados en DatabotLive para cualquier variable de contexto de Watson
 * @description Se debe seguir la estructrura dentro de filters (ejmp salfa)
 * @description El nombre del filtro debe ser IGUAL a la variable de contexto en Watson
 * @description El nombre del item que pertenece al filtro debe ser IGUAL al valor principal (que se asigna a variable contexto) de la entidad en Watson
 * @description Para saber si un bot tiene filtros o no, se usa el método "hasCustomFilters", y dentro de los filtros se buscará el botId
 */

const customFilters = {
  // esto es el getter para saber si hay un botId aqui
  hasCustomFilters(botId) {
    // nunca se activaran los filtros personalizados en DEV o QA
    return this.filters.findIndex(el => el.botId == botId) > -1;
  },
  getFiltersWithValues(botId) {
    let filterWithValues = {};
    this.getFilters(botId).forEach(filter => {
      filterWithValues[filter] = customFilters[botId][filter];
    });
    return filterWithValues;
  },
  getParent(filterName, botId) {
    const filter = this.filters.find(
      el => el.botId == botId && el.name === filterName,
    );
    return filter ? filter.parent : null;
  },
  getChildren(parentName, botId) {
    const filter = this.filters.find(
      el => el.botId == botId && el.parent === parentName,
    );
    return filter ? filter.name : null;
  },
  getFilters(botId) {
    return this.filters.filter(el => el.botId == botId);
  },
  getChildrenWithValues(fatherFilters) {
    let childrenWithValues = {};
    fatherFilters.forEach(filter => {});
  },
  getSubFilters(botId) {
    return Object.keys(customFilters[botId]);
  },
  filters: [
    // esto es para salfa (ID: 861 QA - en prod)
    {
      botId: 861,
      name: 'marca',
      parent: null,
      items: [
        {
          name: 'Camiones Chevrolet',
          formatted: 'Camiones Chevrolet',
        },
        {
          name: 'Chery',
          formatted: 'Chery',
        },
        {
          name: 'CHEVROLET',
          formatted: 'CHEVROLET',
        },
        {
          name: 'Fiat',
          formatted: 'Fiat',
        },
        {
          name: 'Jeep',
          formatted: 'Jeep',
        },
        {
          name: 'MG',
          formatted: 'MG',
        },
        {
          name: 'Mitsubishi',
          formatted: 'Mitsubishi',
        },
        {
          name: 'Nissan',
          formatted: 'Nissan',
        },
        {
          name: 'RAM',
          formatted: 'RAM',
        },
        {
          name: 'SsangYong',
          formatted: 'SsangYong',
        },
        {
          name: 'Toyota',
          formatted: 'Toyota',
        },
      ],
    },
    {
      botId: 861,
      name: 'modelo',
      parent: 'marca',
      items: [
        {
          name: 'NKR 512',
          formatted: 'NKR 512',
          belongTo: 'Camiones Chevrolet',
        },
        {
          name: 'NKR 612',
          formatted: 'NKR 612',
          belongTo: 'Camiones Chevrolet',
        },
        {
          name: 'NPR 715',
          formatted: 'NPR 715',
          belongTo: 'Camiones Chevrolet',
        },
        {
          name: 'NPR 816 AC',
          formatted: 'NPR 816 AC',
          belongTo: 'Camiones Chevrolet',
        },
        {
          name: 'NPR 816 AMT',
          formatted: 'NPR 816 AMT',
          belongTo: 'Camiones Chevrolet',
        },
        {
          name: 'NQR 919',
          formatted: 'NQR 919',
          belongTo: 'Camiones Chevrolet',
        },
        {
          name: 'NPS 816 4x4',
          formatted: 'NPS 816 4x4',
          belongTo: 'Camiones Chevrolet',
        },
        {
          name: 'FRR 1119',
          formatted: 'FRR 1119',
          belongTo: 'Camiones Chevrolet',
        },
        {
          name: 'FTR 1524',
          formatted: 'FTR 1524',
          belongTo: 'Camiones Chevrolet',
        },
        {
          name: 'FVR 1724',
          formatted: 'FVR 1724',
          belongTo: 'Camiones Chevrolet',
        },
        {
          name: 'FVR 1826',
          formatted: 'FVR 1826',
          belongTo: 'Camiones Chevrolet',
        },
        {
          name: 'Blazer',
          formatted: 'Blazer',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'CAMARO',
          formatted: 'CAMARO',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Captiva',
          formatted: 'Captiva',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Colorado',
          formatted: 'Colorado',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Groove',
          formatted: 'Groove',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'N400',
          formatted: 'N400',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Onix',
          formatted: 'Onix',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Sail',
          formatted: 'Sail',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Silverado',
          formatted: 'Silverado',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Spark GT',
          formatted: 'Spark GT',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Spin',
          formatted: 'Spin',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Suburban',
          formatted: 'Suburban',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Tahoe',
          formatted: 'Tahoe',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Tracker',
          formatted: 'Tracker',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Traiblazer',
          formatted: 'Traiblazer',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'Traverse',
          formatted: 'Traverse',
          belongTo: 'CHEVROLET',
        },
        {
          name: 'GLADIATOR',
          formatted: 'GLADIATOR',
          belongTo: 'Jeep',
        },
        {
          name: 'Grand Cherokee',
          formatted: 'Grand Cherokee',
          belongTo: 'Jeep',
        },
        {
          name: 'Renegade',
          formatted: 'Renegade',
          belongTo: 'Jeep',
        },
        {
          name: 'Wrangler Sport',
          formatted: 'Wrangler Sport',
          belongTo: 'Jeep',
        },
        {
          name: '(NUEVO) ASX',
          formatted: '(NUEVO) ASX',
          belongTo: 'Mitsubishi',
        },
        {
          name: 'Eclipse Cross',
          formatted: 'Eclipse Cross',
          belongTo: 'Mitsubishi',
        },
        {
          name: 'Montero Sport',
          formatted: 'Montero Sport',
          belongTo: 'Mitsubishi',
        },
        {
          name: 'New L-200',
          formatted: 'New L-200',
          belongTo: 'Mitsubishi',
        },
        {
          name: 'Outlander',
          formatted: 'Outlander',
          belongTo: 'Mitsubishi',
        },
        {
          name: 'Outlander PHEV',
          formatted: 'Outlander PHEV',
          belongTo: 'Mitsubishi',
        },
        {
          name: 'Nuevo Crolla Cross',
          formatted: 'Nuevo Crolla Cross',
          belongTo: 'Toyota',
        },
        {
          name: 'Gazoo Racing',
          formatted: 'Gazoo Racing',
          belongTo: 'Toyota',
        },
        {
          name: 'Raize',
          formatted: 'Raize',
          belongTo: 'Toyota',
        },
        {
          name: 'Yaris',
          formatted: 'Yaris',
          belongTo: 'Toyota',
        },
        {
          name: 'Camry',
          formatted: 'Camry',
          belongTo: 'Toyota',
        },
        {
          name: 'Corolla',
          formatted: 'Corolla',
          belongTo: 'Toyota',
        },
        {
          name: 'Yaris Sport',
          formatted: 'Yaris Sport',
          belongTo: 'Toyota',
        },
        {
          name: 'Fortuner',
          formatted: 'Fortuner',
          belongTo: 'Toyota',
        },
        {
          name: 'Hilux',
          formatted: 'Hilux',
          belongTo: 'Toyota',
        },
        {
          name: 'All New Rush',
          formatted: 'All New Rush',
          belongTo: 'Toyota',
        },
        {
          name: 'C-HR',
          formatted: 'C-HR',
          belongTo: 'Toyota',
        },
        {
          name: 'Land Cruiser Prado',
          formatted: 'Land Cruiser Prado',
          belongTo: 'Toyota',
        },
        {
          name: 'All New Musso',
          formatted: 'All New Musso',
          belongTo: 'SsangYong',
        },
        {
          name: 'GRAND MUSSO',
          formatted: 'GRAND MUSSO',
          belongTo: 'SsangYong',
        },
        {
          name: 'Korando',
          formatted: 'Korando',
          belongTo: 'SsangYong',
        },
        {
          name: 'New Rexton',
          formatted: 'New Rexton',
          belongTo: 'SsangYong',
        },
        {
          name: 'Tivoli',
          formatted: 'Tivoli',
          belongTo: 'SsangYong',
        },
        {
          name: '(NUEVA) Qashqai',
          formatted: '(NUEVA) Qashqai',
          belongTo: 'Nissan',
        },
        {
          name: 'Kicks',
          formatted: 'Kicks',
          belongTo: 'Nissan',
        },
        {
          name: 'MURANO',
          formatted: 'MURANO',
          belongTo: 'Nissan',
        },
        {
          name: 'NAVARA',
          formatted: 'NAVARA',
          belongTo: 'Nissan',
        },
        {
          name: 'NEW VERSA',
          formatted: 'NEW VERSA',
          belongTo: 'Nissan',
        },
        {
          name: 'NUEVO SENTRA',
          formatted: 'NUEVO SENTRA',
          belongTo: 'Nissan',
        },
        {
          name: 'NV350 CARGO',
          formatted: 'NV350 CARGO',
          belongTo: 'Nissan',
        },
        {
          name: 'NV350 ESCOLAR',
          formatted: 'NV350 ESCOLAR',
          belongTo: 'Nissan',
        },
        {
          name: 'PATHFINDER',
          formatted: 'PATHFINDER',
          belongTo: 'Nissan',
        },
        {
          name: 'X-TRAIL',
          formatted: 'X-TRAIL',
          belongTo: 'Nissan',
        },
        {
          name: '(NEW) Tiggo 3',
          formatted: '(NEW) Tiggo 3',
          belongTo: 'Chery',
        },
        {
          name: 'Arrizo 5',
          formatted: 'Arrizo 5',
          belongTo: 'Chery',
        },
        {
          name: 'JS4',
          formatted: 'JS4',
          belongTo: 'Chery',
        },
        {
          name: 'Tiggo 2',
          formatted: 'Tiggo 2',
          belongTo: 'Chery',
        },
        {
          name: 'Tiggo 7 (PRO)',
          formatted: 'Tiggo 7 (PRO)',
          belongTo: 'Chery',
        },
        {
          name: 'TIGGO 8',
          formatted: 'TIGGO 8',
          belongTo: 'Chery',
        },
        {
          name: 'Otros',
          formatted: 'Otros',
          belongTo: 'Chery',
        },
        {
          name: 'MG5',
          formatted: 'MG5',
          belongTo: 'MG',
        },
        {
          name: 'MG RX5',
          formatted: 'MG RX5',
          belongTo: 'MG',
        },
        {
          name: 'MG ZS',
          formatted: 'MG ZS',
          belongTo: 'MG',
        },
        {
          name: 'MG ZX',
          formatted: 'MG ZX',
          belongTo: 'MG',
        },
        {
          name: 'New MG 3',
          formatted: 'New MG 3',
          belongTo: 'MG',
        },
        {
          name: 'New MG 6',
          formatted: 'New MG 6',
          belongTo: 'MG',
        },
        {
          name: 'NEW MG HS',
          formatted: 'NEW MG HS',
          belongTo: 'MG',
        },
        {
          name: 'NEW MG ZS EV',
          formatted: 'NEW MG ZS EV',
          belongTo: 'MG',
        },
        {
          name: 'Argo',
          formatted: 'Argo',
          belongTo: 'Fiat',
        },
        {
          name: 'Ducato',
          formatted: 'Ducato',
          belongTo: 'Fiat',
        },
        {
          name: 'Ducato Minibus',
          formatted: 'Ducato Minibus',
          belongTo: 'Fiat',
        },
        {
          name: 'MOBI',
          formatted: 'MOBI',
          belongTo: 'Fiat',
        },
        {
          name: 'Uno Way',
          formatted: 'Uno Way',
          belongTo: 'Fiat',
        },
        {
          name: '(NEW) Ram 2500',
          formatted: '(NEW) Ram 2500',
          belongTo: 'RAM',
        },
        {
          name: 'New Ram 1000',
          formatted: 'New Ram 1000',
          belongTo: 'RAM',
        },
        {
          name: 'New Ram 1500',
          formatted: 'New Ram 1500',
          belongTo: 'RAM',
        },
        {
          name: 'Ram 700',
          formatted: 'Ram 700',
          belongTo: 'RAM',
        },
        {
          name: 'Ram 1500',
          formatted: 'Ram 1500',
          belongTo: 'RAM',
        },
        {
          name: 'Ram V700 City',
          formatted: 'Ram V700 City',
          belongTo: 'RAM',
        },
        {
          name: 'Ram V700 Rapid',
          formatted: 'Ram V700 Rapid',
          belongTo: 'RAM',
        },
        {
          name: 'Ram V1000',
          formatted: 'Ram V1000',
          belongTo: 'RAM',
        },
      ],
    },
    {
      botId: 861,
      name: 'ciudad',
      parent: null,
      items: [
        {
          name: 'Antofagasta',
          formatted: 'Antofagasta',
        },
        {
          name: 'Calama',
          formatted: 'Calama',
        },
        {
          name: 'Concepción',
          formatted: 'Concepción',
        },
        {
          name: 'Copiapó',
          formatted: 'Copiapó',
        },
        {
          name: 'Coquimbo',
          formatted: 'Coquimbo',
        },
        {
          name: 'Iquique',
          formatted: 'Iquique',
        },
        {
          name: 'La Serena',
          formatted: 'La Serena',
        },
        {
          name: 'Ovalle',
          formatted: 'Ovalle',
        },
        {
          name: 'Santiago',
          formatted: 'Santiago',
        },
        {
          name: 'Temuco',
          formatted: 'Temuco',
        },
        {
          name: 'ciudad_otros',
          formatted: 'Otras ciudades',
        },
      ],
    },
    {
      botId: 1245,
      name: 'proyecto',
      aux_names: ['Proyecto'], // atributo secundario para busqueda
      parent: null,
      items: [
        {
          name: 'Edificio Aires Malbec en La Dehesa',
          formatted: 'Edificio Aires Malbec en La Dehesa',
        },
        {
          name: 'Edificio Europa en la comuna de Providencia',
          formatted: 'Edificio Europa en la comuna de Providencia',
        },
        {
          name: 'IN Macul',
          formatted: 'IN Macul',
        },
      ],
    },
    {
      botId: 1898,
      name: 'solicitudes',
      aux_names: ['solicitudes'], // atributo secundario para busqueda
      parent: null,
      items: [
        {
          name: 'Ingreso de avería',
          formatted: 'Ingreso de avería',
        },
        {
          name: 'Estado de vehículo',
          formatted: 'Estado de vehículo',
        },
        {
          name: 'Estado de resolución',
          formatted: 'Estado de resolución',
        },
        {
          name: 'Copia de contrato',
          formatted: 'Copia de contrato',
        },
        {
          name: 'Informar ingreso a taller',
          formatted: 'Informar ingreso a taller',
        },
        {
          name: 'Confirmar agendamiento',
          formatted: 'Confirmar agendamiento',
        },
        {
          name: 'Hablar con ejecutivo',
          formatted: 'Hablar con ejecutivo',
        },
        {
          name: 'Otras consultas',
          formatted: 'Otras consultas',
        },
      ],
    },
    {
      botId: 1910,
      name: 'area',
      aux_names: ['area'], // atributo secundario para busqueda
      label: 'Área',
      parent: null,
      items: [
        {
          name: 'sac',
          formatted: 'Área de SAC',
        },
        {
          name: 'comercial',
          formatted: 'Área Comercial',
        },
        {
          name: 'logística',
          formatted: 'Área Logística',
        },
        {
          name: 'transportes',
          formatted: 'Área de Transporte',
        },
      ],
    },
  ],
};

export default customFilters;
