import axios from 'axios';
import environment from '@/environment.js';

export default {
  list() {
    return axios.get(`${environment.DATABOT_TEMPLATES}/template/intents`);
  },
  byTemplateId(template_id) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/template/nodes/${template_id}`,
    );
  },
  byNodeId(node_id) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/template/nodes/byid/${node_id}`,
    );
  },
  byIntentName(intent_name, bot_id, template) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/template/nodes/answers/by_intent/${intent_name}?bot_id=${bot_id}&template=${template}`,
    );
  },
  ByIntentNameExtended(intent_name) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/template/nodes/answers/by_name/${intent_name}`,
    );
  },
  getById(intent_id) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/template/intents/byid/${intent_id}`,
    );
  },
  getBySectionName(name) {
    return axios.get(
      `${environment.DATABOT_TEMPLATES}/api/template/intents/bysection/${name}`,
    );
  },
};
