<template>
  <FadeTransition :duration="200" v-show="show">
    <section class="no-access-view">
      <span class="mb-2">Lo sentimos, no tienes acceso a este módulo.</span>
      <router-link class="click" to="/dashboard">Volver al dashboard</router-link>
    </section>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from "vue2-transitions";

export default {
  name: "NoAccessView",
  props: ["show"],
  components: {
    FadeTransition
  },
  mounted() {
    this.setShowView();
  },
  methods: {
    setShowView() {
      setTimeout(() => {
        this.$emit("loading");
      }, 1200);
    }
  }
}
</script>

<style lang="scss">
.no-access-view {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}
</style>