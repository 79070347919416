<template>
  <section class="bullet-steps">
    <span :class="{ 'item bullet-1': true, active: step >= 1 }" />
    <span :class="{ 'item bullet-2': true, active: step >= 2 }" />
    <span :class="{ 'item bullet-3': true, active: step >= 3 }" />
  </section>
</template>

<script>
export default {
  name: 'Steps',
  props: ['step'],
};
</script>

<style lang="scss" scoped>
.bullet-steps {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  
  @media(max-width: 768px) {
    display: none;
  }
  .item {
    display: inline-block;
    width: .75rem;
    height: .75rem;
    background-color: #ffffff;
    border: 1px solid #767676;
    border-radius: 1rem;
    margin: .5rem;
  }
  .active {
    background-color: #2981EF;
    border-color: #2981EF;
  }
}
</style>
