<template>
  <SlideYUpTransition :duration="duration" >
    <div
      class="modal fade"
      @click.self="closeModal"
      :class="[{'show d-block': show}, {'d-none': !show}, {'modal-mini': type === 'mini'}]"
      v-show="show"
      tabindex="-1"
      :aria-hidden="!show"
    >
      <div
        class="modal-dialog modal-dialog-centered justify-content-center text-center"
        :class="[{'modal-notice': type === 'notice'}, modalClasses]"

      >
        <div
          class="modal-content mx-0"
          :class="[gradient ? `bg-gradient-${gradient}` : '',modalContentClasses]"
          :style="{width:ancho+'px',height:'100%' }"
        >
          <div class="modal-header" >
            <slot name="header"></slot>
            <slot name="close-button">
              <button
                type="button"
                class="close"
                @click= "$emit('close')"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span :aria-hidden="!show">×</span>
              </button>
            </slot>
          </div>

          <div class="modal-body" :class="bodyClasses">
            <slot name="content"></slot>
          </div>

          <div class="modal-footer" :class="footerClasses" v-if="$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';

export default {
  name: 'Modal',
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      duration: 600,
    };
  },
  props: {
    show: Boolean,
    ancho: '',
    alto: '',
    showClose: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        const acceptedValues = ['', 'notice', 'mini'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") ',
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes',
    },
    modalContentClasses: {
      type: [Object, String],
      description: 'Modal dialog content css classes',
    },
    gradient: {
      type: String,
      description: 'Modal gradient type (danger, primary etc)',
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes',
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes',
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes',
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
  },
  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        documentClasses.add('modal-open');
      } else {
        documentClasses.remove('modal-open');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  height: 100vh;
  z-index: 202; // bar buttonbost 200
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: hidden;
  justify-content: center;
  margin: auto;

  &-content {
    border-radius: 1rem !important;
  }
  &-header {
    height: 10px;
  }
  &-body {
    padding: 0;
    margin: 0;
    overflow: auto;
  }
  &-content {
    margin: 0 auto;
    overflow: unset !important;
    box-shadow: 0 0 30px rgba(0,0,0,0.2);
  }
  &-dialog {
    max-width: 1000px;
  }
}
</style>
