import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import modules from './modules';
import {
  localStorageGetItem,
  localStorageRemove,
  localStorageSetItem,
} from '@/utils/utils';

Vue.use(Vuex);

// verificando que en localstorage esté botIds de tipo array
try {
  JSON.parse(localStorageGetItem('botIds'));
} catch (error) {
  localStorageRemove('botIds');
}

// TODO: Mejorar esto con id dinamica
const defaultPlan = {
  buttom: 'Contratar',
  created_at: '2020-0,6-27T03:24:31.097Z',
  display_order: 3,
  id: 27,
  info1: 'Hasta 350 Contactos',
  info2: 'Sin límite de mensajes',
  info3: 'Chatbot personalizable',
  info4: 'Databot live',
  info5: 'Gestión Clientes (CRM)',
  info6: 'Hasta 7 agentes',
  iva: 'IVA Incluido',
  max_agents: 7,
  max_leads: 350,
  name: 'Plan Profesional',
  period: 'monthly',
  price: 19990,
  product_type: 'chatbot',
  updated_at: '2020-06-27T03:24:31.097Z',
};

const store = new Vuex.Store({
  modules,
  state: {
    platform_name: '',
    platform_shop: '',
    platform_code: '',
    platform_access: '',
    providers: [],
    skip: false,
    user: {
      id: localStorageGetItem('user_id') || '',
      name: localStorageGetItem('name') || '',
      email: localStorageGetItem('email') || '',
      phone: localStorageGetItem('phone') || '',
      image: localStorageGetItem('image') || '',
      token: localStorageGetItem('token') || '',
      botIds: localStorageGetItem('botIds')
        ? JSON.parse(localStorageGetItem('botIds'))
        : [],
      customerId: localStorageGetItem('customerId') || '',
      url: localStorageGetItem('url') || '',
    },
    leadDetail: {},
    // Proceso de registro
    registerData: {
      user: localStorageGetItem('register_user') || '',
      customerId: localStorageGetItem('register_customerId') || '',
      bot: localStorageGetItem('register_bot') || '',
      botName: localStorageGetItem('register_botName') || '',
      botToken: '',
      step: localStorageGetItem('register_step') || 1,
    },
    // Fin Proceso de registro
    count: 0,
    active_view: 'my_bots',
    selected_bot: {},
    store_skill_base: [],
    skill_template: {},
    new_message: false,
    show_client_info: false,
    activeClient: false,
    showSidebarMobile: false,
    agentAccount: null,
    push_data: {},
    selectedChat: null,
    getSocketLeads: [],
    plan_name: '',
    logged: localStorageGetItem('logged') || 'false',
    modal: {
      start: false,
    },
    activeChatRooms: [],
    activeChatRoomTokens: [],
    bots: [],
    botsWithBusinessTypeShow: [],
    ownerBots: [],
    isSocketInitialized: false,

    checkoutData: {
      plan_data: localStorageGetItem('plan_data')
        ? JSON.parse(localStorageGetItem('plan_data'))
        : {},
      // channel_data: localStorageGetItem('channel_data')
      //   ? JSON.parse(localStorageGetItem('channel_data'))
      //   : {},
      product: localStorageGetItem('product')
        ? JSON.parse(localStorageGetItem('product'))
        : {},
      subscription: localStorageGetItem('subscription')
        ? JSON.parse(localStorageGetItem('subscription'))
        : {},
      plan_period: localStorageGetItem('plan_period') || '',
      bot_id: localStorageGetItem('bot_id') || '',
    },
    roomToken: null,
    templates: [
      {
        name: 'ecommerce',
        label: 'Ecommerce',
        value: 'TEST001',
      },
      {
        name: 'generica',
        label: 'Genérica',
        value: 'generica',
      },
    ],
    selectedTemplate: null,
    tutorials: [],
    selected_video: {},
    move_eje_x: 470,
    // TODO for roles ->
    all_roles: {},
    role_permission: {},
    view_permissions: {},
    user_role: '',
    ecommerceAccount: {
      show_bar: localStorageGetItem('show_bar' || null),
      show_done_bar: localStorageGetItem('show_done_bar' || null),
      upload_progress: localStorageGetItem('upload_progress' || 0),
    },
    testAccounts: ['victor@databot.cl', 'vj10@gmail.com'],
    global_loading: false,
    audio_ready: false,
    new_notifications: {},
    notifications: [],
    notification_socket: {},
    notification_bullet: false,
    from_query: '',
  },

  mutations: {
    setCurrentRoomToken(state, data) {
      state.roomToken = data;
    },
    setLeadDetail(state, newLeadDetail) {
      state.leadDetail = newLeadDetail;
      // console.log("Aqui mutacion ->", newLeadDetail);
    },
    loginAction(state, payload) {
      state.logged = 'true';
      state.user.id = payload.user.id;
      state.user.name = payload.user.name ? payload.user.name : '';
      state.user.email = payload.user.email ? payload.user.email : '';
      state.user.phone = payload.user.phone ? payload.user.phone : '';
      state.user.image = payload.user.image ? payload.user.image : '';
      // state.user.token = payload.token;
      state.user.botIds = payload.botIds;
      state.user.customerId = payload.customerId;
      // agrego el campo url capturado en el registro paso 1
      state.user.url = payload.url;
      // En local storage los elementos no pueden tener -
      localStorageSetItem('logged_local', 'true');
      localStorageSetItem('logged', 'true');
      localStorageSetItem('user_id', payload.user.id);
      localStorageSetItem('name', payload.user.name ? payload.user.name : '');
      localStorageSetItem(
        'email',
        payload.user.email ? payload.user.email : '',
      );
      localStorageSetItem(
        'phone',
        payload.user.phone ? payload.user.phone : '',
      );
      localStorageSetItem(
        'image',
        payload.user.image ? payload.user.image : '',
      );
      localStorageSetItem(
        'customerId',
        payload.customerId ? payload.customerId : '',
      );
      // localStorageSetItem("token", payload.token);
      // localStorageSetItem("tokenType", payload.tokenType);
      // localStorageSetItem("client", payload.client);
      // localStorageSetItem("expiry", payload.expiry);
      // localStorageSetItem("uid", payload.uid);
      localStorageSetItem('botIds', JSON.stringify(payload.botIds));
      // To handle ecommerce account - cms credentials & product upload
    },
    // * los valores no se deben setear a 0
    // * Js los interpreta como strings '0' y deja de se un null
    logout(state) {
      // Eliminando storage de notificaciones sonido y relacionados
      localStorageRemove('userSounds');
      state.activeChatRoomTokens = [];
      state.bots = [];
      // lo demas
      localStorageSetItem('logged_local', 'false');
      localStorageSetItem('logged', 'false');
      localStorageSetItem('user_id', '');
      localStorageSetItem('name', '');
      localStorageSetItem('email', '');
      localStorageSetItem('phone', '');
      localStorageSetItem('image', '');
      localStorageSetItem('botIds', JSON.stringify([]));
      // Authorization
      localStorageSetItem('token', '');
      localStorageSetItem('tokenType', '');
      localStorageSetItem('client', '');
      localStorageSetItem('expiry', '');
      localStorageSetItem('uid', '');
      // Ecommerce Account
      localStorageSetItem('show_bar', null);
      localStorageSetItem('show_done_bar', null);
      localStorageSetItem('upload_progress', 0);
      localStorageSetItem('url', '');
      state.logged = 'false';
      state.user.id = '';
      state.user.name = '';
      state.user.email = '';
      state.user.phone = '';
      state.user.image = '';
      state.user.botIds = [];
      state.ownerBots = [];
      state.selected_bot = {};
      state.new_message = false;
      state.show_client_info = false;
      state.push_data = {};
      state.selectedChat = null;
      state.getSocketLeads = [];
      state.activeClient = false;
      state.showSidebarMobile = false;
      state.agentAccount = null;
      state.platform_name = '';
      state.platform_code = '';
      state.platform_shop = '';
      state.platform_access = '';
      state.plan_name = '';
      state.skip = false;
      state.tutorials = [];
      state.selected_video = {};
      state.move_eje_x = 470;
      state.all_roles = {};
      state.role_permission = {};
      state.view_permissions = {};
      state.user_role = '';
      state.ecommerceAccount.show_bar = null;
      state.ecommerceAccount.show_done_bar = null;
      state.ecommerceAccount.upload_progress = 0;
      state.active_view = 'my_bots';
      state.global_loading = false;
      state.providers = [];
      state.new_notifications = {};
      state.notifications = [];
      state.notification_socket = {};
      state.notification_bullet = false;
      state.audio_ready = false;
      state.from_query = '';
    },

    changeImage(state, img) {
      localStorageSetItem('image', img);
      state.user.image = img;
    },

    changeName(state, name) {
      localStorageSetItem('name', name);
      state.user.name = name;
    },

    setActiveView(state, active_view) {
      state.active_view = active_view;
    },
    setSelectedBot(state, selected_bot) {
      state.selected_bot = selected_bot;
    },
    setShowClientInfo(state, show_client_info) {
      state.show_client_info = show_client_info;
    },
    setPlanName(state, plan_name) {
      state.plan_name = plan_name;
    },
    editSelectedBot(state, update_selected_bot) {
      state.selected_bot = update_selected_bot;
      if (update_selected_bot.id) {
        let index = state.ownerBots.findIndex(
          (el) => el.id == update_selected_bot.id,
        );
        state.ownerBots[index] = update_selected_bot;
        state.ownerBots = state.ownerBots.slice();
      }
    },
    // To init notifications system ->
    // Set new lead interaction data
    setPushData(state, push_data) {
      state.push_data = push_data;
    },
    // Set bollean to new interaction with bot
    setNewMessage(state, new_message) {
      state.new_message = new_message;
    },
    setSelectedChat(state, selectedChat) {
      state.selectedChat = selectedChat;
    },
    // Set sidebar to Mobile
    setShowSidebarMobile(state, showSidebarMobile) {
      state.showSidebarMobile = showSidebarMobile;
    },
    // Set sidebar to Mobile
    setSocketLeads(state, value) {
      state.getSocketLeads.push(value);
    },
    // selected agent account
    setAgentAccount(state, agentAccount) {
      state.agentAccount = agentAccount;
    },
    /* Ecommerce account */
    setEcommerceAccount(state, payload) {
      state.ecommerceAccount.show_bar = payload.show_bar;
      state.ecommerceAccount.show_done_bar = payload.show_done_bar;
      state.ecommerceAccount.upload_progress = payload.upload_progress;
      localStorageSetItem('show_bar', payload.show_bar);
      localStorageSetItem('show_done_bar', payload.show_done_bar);
      localStorageSetItem('upload_progress', payload.upload_progress);
    },
    /* VIDEOS */
    setSelectedVideo(state, selected_video) {
      state.selected_video = selected_video;
    },
    setMoveEjeX(state, move_eje_x) {
      state.move_eje_x = move_eje_x;
    },
    setAllRoles(state, all_roles) {
      state.all_roles = all_roles;
    },
    setPermissions(state, role_permission) {
      state.role_permission = role_permission;
    },
    setCurrentPermissionsView(state, view_permissions) {
      state.view_permissions = view_permissions;
    },
    setUserRole(state, user_role) {
      state.user_role = user_role;
    },
    setRegisterProcess(state, payload) {
      state.registerData.user = payload.user;
      state.registerData.bot = payload.bot;
      state.registerData.botName = payload.botName;
      state.registerData.botToken = payload.botToken;
      state.registerData.customerId = payload.customerId;
      state.registerData.step = payload.step;
      localStorageSetItem('register_user', payload.user);
      localStorageSetItem('register_bot', payload.bot);
      localStorageSetItem('register_botName', payload.botName);
      localStorageSetItem('register_step', payload.step);
      localStorageSetItem('register_customerId', payload.customerId);
    },

    setPlan(state, plan) {
      state.plan = plan;
    },

    setBot(state, bot) {
      state.bot = bot;
    },

    // Limpia los datos de Vuex y del LocalStorage
    cleanRegisterData(state) {
      state.registerData.user = '';
      state.registerData.bot = '';
      state.registerData.botName = '';
      state.registerData.botToken = '';
      state.registerData.customerId = '';
      state.registerData.step = 1;

      localStorage.removeItem('register_user');
      localStorage.removeItem('register_bot');
      localStorage.removeItem('register_botName');
      localStorage.removeItem('register_customerId');
      localStorageSetItem('register_step', 1);
    },

    cleanCheckoutData(state) {
      state.checkoutData.plan_data = {};
      // state.checkoutData.channel_data = {};
      state.checkoutData.product = {};
      state.checkoutData.plan_period = '';
      state.checkoutData.bot_id = '';
      state.checkoutData.subscription = {};

      localStorage.removeItem('plan_data');
      // localStorage.removeItem('channel_data');
      localStorage.removeItem('product');
      localStorage.removeItem('plan_period');
      localStorage.removeItem('bot_id');
      localStorage.removeItem('subscription');
    },

    showmodal(state, value) {
      state.modal.start = value;
    },

    setActiveChatRooms(state, value) {
      state.activeChatRooms = [];
      state.activeChatRooms = value || [];
    },

    addActiveChatRoom(state, value) {
      if (!state.activeChatRooms.includes(value)) {
        state.activeChatRooms.unshift(value);
      }
    },

    setActiveChatTokens(state, value) {
      state.activeChatRoomTokens = [];
      state.activeChatRoomTokens = value;
    },

    addActiveChatTokens(state, value) {
      if (!state.activeChatRoomTokens.includes(value)) {
        state.activeChatRoomTokens.push(value);
      }
    },
    setActiveBots(state, value) {
      state.bots = value;
    },
    setBotIdsToLocalStorage(state, botIds) {
      state.user.botIds = botIds;
      localStorageSetItem('botIds', JSON.stringify(botIds));
    },
    setBotsWithBusinessTypeShow(state, bots) {
      state.botsWithBusinessTypeShow = bots;
    },
    setOwnerBots(state, bots) {
      state.ownerBots = bots;
    },
    initializeSockets(state) {
      state.isSocketInitialized = true;
    },

    setCheckoutData(state, payload) {
      state.checkoutData.plan_data = payload.plan_data;
      // state.checkoutData.channel_data = payload.channel_data;
      state.checkoutData.product = payload.product;
      state.checkoutData.plan_period = payload.plan_period;
      state.checkoutData.bot_id = payload.bot_id;
      state.checkoutData.subscription = payload.subscription;

      localStorageSetItem('plan_data', JSON.stringify(payload.plan_data));
      // localStorageSetItem('channel_data', JSON.stringify(payload.channel_data));
      localStorageSetItem('product', JSON.stringify(payload.product));
      localStorageSetItem('subscription', JSON.stringify(payload.subscription));
      localStorageSetItem('plan_period', payload.plan_period);
      localStorageSetItem('bot_id', payload.bot_id);

      // state.checkoutData.plan_name = payload.plan_name;
      // state.checkoutData.plan_price = parseFloat(payload.plan_price);
      // state.checkoutData.plan_conversations = payload.plan_conversations;
      // state.checkoutData.plan_period = payload.plan_period;
      // state.checkoutData.plan_info = payload.plan_info;
      // state.checkoutData.plan_agents = parseInt(payload.plan_agents);

      // localStorageSetItem('plan_name', payload.plan_name);
      // localStorageSetItem('plan_price', payload.plan_price);
      // localStorageSetItem('plan_conversations', payload.plan_conversations);
      // localStorageSetItem('plan_period', payload.plan_period);
      // localStorageSetItem('plan_info', JSON.stringify(payload.plan_info));
      // localStorageSetItem('plan_agents', payload.plan_agents);
    },
    toggleSkipGuide(state) {
      state.skip = !state.skip;
    },
    getPlatformName(state, name) {
      state.platform_name = name;
    },
    getPlatformShop(state, name) {
      state.platform_shop = name;
    },
    getPlatformCode(state, code) {
      state.platform_code = code;
    },
    getPlatformAccess(state, code) {
      state.platform_access = code;
    },
    setRouterSkillBase(state, store_skill_base) {
      state.store_skill_base = store_skill_base;
    },
    setTemplateValue(state, skill_template) {
      state.skill_template = skill_template;
    },
    selectedTemplate(state, selectedTemplate) {
      state.selectedTemplate = selectedTemplate;
    },
    setGlobalLoading(state, global_loading) {
      state.global_loading = global_loading;
    },
    setProviders(state, providers) {
      state.providers = providers
    },
    setAddNotification(state, { new_notifications, event_type, read, user_id }) {
      let new_obj = {
        item: new_notifications,
        read,
        user_id
      };
      state.new_notifications = new_obj;
      state.notifications = [new_obj, ...state.notifications];      
    },
    setUpdateNotification(state, notification) {
      let item = state.notifications.find(el => el.item.id == notification.item.id)
      if (item) item.read = true;
    },
    setDeleteNotification(state, notification_id) {
      let index = state.notifications.findIndex(el => el.item.id == notification_id);
      state.notifications.splice(index, 1);
    },
    getNotifications(state, notifications) {
      if (notifications.length === 0) return state.notifications = [];
      else state.notifications = [...state.notifications, ...notifications];
    },
    setNotificationSocket(state, notification_socket) {
      state.notification_socket = notification_socket
    },
    setNotificationBullet(state, notification_bullet) {
      state.notification_bullet = notification_bullet
    },
    readyToSendAudioMessage(state, audio_ready) {
      state.audio_ready = audio_ready
    },
    setFromQuery(state, value) {
      state.from_query = value
    }
  },
  actions: {
    logout({ commit }) {
      commit('logout');
      router.push({ name: 'login' });
    },
  },
});

export default store;
